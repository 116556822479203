import 'mapbox-gl/dist/mapbox-gl.css';

import React, {createRef, ReactNode, RefObject} from 'react';

import {GeoDataService} from './data/GeoDataService';
import {
    Fairway,
    FairwayKilometerMark,
    Hectopunt,
    PathOfInterest,
    PointOfInterest,
    RegionMeta,
    Road,
    RoadObject,
    VtsSector
} from './data/GeoData';
import {Feature, Position} from 'geojson';

import {LngLatBounds} from 'mapbox-gl';

import {MissionResult} from './MissionResult';
import {AnswerRetriever, MissionMap} from './MissionMap';
import {GeoUtil} from './util/GeoUtil';
import {QuestionResult} from './QuestionResult';
import {QuestionProgressIndicator} from './QuestionProgressIndicator';
import {QuestionTimer} from './QuestionTimer';
import {MathUtil} from './util/MathUtil';
import pinResult from './img/pin_result.png';
import pin from './img/pin.png';
import './styles/_mission.scss';
import {MissionScoreBanner} from './components/MissionScoreBanner';
import {PreviousButton} from './components/PreviousButton';
import QuestionSign from './question-types/Question';
import config from './config.json';
import {BackendService} from "./data/BackendService";
import {ExplanationPopup} from "./components/ExplanationPopup";
import blob from './img/blob-top-right.svg';
import closePopup from './img/closePopUp.svg'
import Loading from "./components/Loading";
import './styles/_explanation.scss'
import {MissionQuitButton} from "./components/MissionQuitButton";
import {QuestionConfig, QuestionType} from './config/ConfigService';

export enum MissionMode { Droog = "Droog", Nat = "Nat" }

export type QuestionDataType =
    Road
    | RoadObject
    | Hectopunt
    | Fairway
    | PointOfInterest
    | FairwayKilometerMark
    | PathOfInterest;
export type Question = {
    type: QuestionType.Road,
    data: Road,
} | {
    type: QuestionType.RoadObject,
    data: RoadObject,
} | {
    type: QuestionType.Hectopunt,
    data: Hectopunt,
} | {
    type: QuestionType.Fairway,
    data: Fairway,
} | {
    type: QuestionType.PointOfInterest,
    data: PointOfInterest
} | {
    type: QuestionType.FairwayKilometerMark,
    data: FairwayKilometerMark
} | {
    type: QuestionType.PathOfInterest,
    data: PathOfInterest
} | {
    type: QuestionType.VtsSector,
    data: VtsSector
}

interface ScoreSettings {
    distance: {
        max: number,
        maxScore: number,
    }
    time: {
        max: number,
        maxScore: number,
    }
}

interface MissionProps {
    mode: MissionMode,
    region: string,
    missionLevel: number,
    questionConfigs: QuestionConfig[],
    scoreSettings: ScoreSettings,
}

interface MissionState {
    // Mission metadata:
    regionMeta: RegionMeta | null;
    otherRegionsGeometry?: Feature[];
    regionBounds: LngLatBounds;
    questions: Question[];

    // Question flow
    phase: "loading" | "ready" | "answering" | "showingAnswer" | "savingResult" | "complete";
    currentQuestion: number;
    selectedPosition: Position | null;
    answerDistances: number[],
    answerTimes: number[],
    answerScores: number[],

    // Map state:
    mapLoaded: boolean;
    game: boolean,
    mouse: boolean
    popupOpen: boolean
    backgroundImage:string
}

export class Mission extends React.Component<MissionProps, MissionState> {
    currentUrl = window.location.pathname.split('/')
    private readonly missionMapRef: RefObject<MissionMap>;
    private readonly timerRef: RefObject<QuestionTimer>;
    private backend = BackendService.getInstance();
    // TODO give this a better name (as it's not just the start button)
    removeStartButton = false
    droogImages = [
        '2019WISautoduimomhoog(Large).JPG',
        'A4Beneluxtunnel(Large).jpg',
        'A4PrinsClausplein(Large).jpg',
        'A7afsluitdijkdenOever(Large).jpg',
        'A12IJsselbrugnarenovatie(Large).jpg',
        'A15Tunnelenbrugoverde Noord(Large).jpg',
        'A16Brienenoordbrug(Large).jpg',
        'A20GiessenbrugRotterdamA20(Large).jpg',
        'A22Velsertunnel(Large).jpg',
        'A29Haringvlietbrug(Large).jpg',
        'A44Lisserwegviaduct(Large).jpg',
        'DampoortAquaductZN(Large).jpg',
        'KoningWillemAlexandertunnelA2(Large).jpg',
        'N31AquaductHarinxmakanaal(Large).jpg',
        'N210Algerabrug(Large).jpg',
        'VERKEERSCENTRALE(Large).jpg',
        'VerkeerscentraleUtrecht(Large).jpg',
        'WaalbrugNijmegen(Large).jpg'
    ]
    natImages = [
        '2019Tetrisschipdef.(Large).jpg',
        '2022fotoschipjoostjanssen(Large).jpg',
        'Beatrixsluis(Large).jpg',
        'IJsselmeervuurtorenUrk(Large).jpg',
        'KeringRaavenswaaij(Large).jpg',
        'KrammerbrugenKrammersluizen(Large).jpg',
        'MAASsluisSambeekROB_2250(Large).jpg',
        'MaximasluizenLith(Large).jpg',
        'Oosterscheldekering(Large).jpg',
        'SluisEefde(Large).jpg',
        'sluisHansweert(Large).jpg',
        'sluisTerneuzenconstruction(Large).tif',
        'sluizencomplexBelfeldZN(Large).jpg',
        'StuwBorgharen(Large).jpg',
        'StuwGrave(Large).jpg',
        'Topshuisoosterscheldekering(Large).jpg',
        'verkeerspostNijmegen(Large).jpg',
        'verkeerspostHansweert(Large).jpg',
        'WilhelminasluisAndel(Large).jpg'
    ]
    // @ts-ignore
    state: MissionState = {
        // optional second annotation for better type inference
        regionMeta: null,
        otherRegionsGeometry: undefined,
        regionBounds: GeoUtil.defaultBounds,
        questions: [],
        currentQuestion: -1,
        selectedPosition: null,
        phase: "loading",
        answerDistances: [],
        answerTimes: [],
        answerScores: [],
        mapLoaded: false,
        game: false,
        mouse: false,
        popupOpen: false
    };

    constructor(props: MissionProps) {
        super(props);

        this.missionMapRef = createRef<MissionMap>()
        this.timerRef = createRef<QuestionTimer>()
    }
    getRandomDroogImage(mode:string){
        if (mode === 'droog') {
            const baseUrl = '/img/connie/DROOG/'
            const random = Math.floor(Math.random() * this.droogImages.length);
            this.setState({
                backgroundImage:baseUrl+ this.droogImages[random]
            })
        } else if (mode === 'nat') {
            const baseUrl = '/img/connie/NAT/'
            const random = Math.floor(Math.random() * this.natImages.length);
            this.setState({
                backgroundImage:baseUrl+ this.natImages[random]
            })
        } else {
            this.setState({
                backgroundImage: '../img/connie/DROOG/A16Brienenoordbrug(Large).jpg'
            })
        }
    }
    componentDidMount() {

        const geoDataService = GeoDataService.getInstance();
        const regionPromise = this.props.mode === MissionMode.Nat ? geoDataService.getNatRegions() : geoDataService.getDroogRegions();
        const questionsPromise = geoDataService.buildQuestionPromise(this.props.region, this.props.questionConfigs);

        Promise.all([regionPromise, questionsPromise])
            .then(responses => {
                const regionMetaResponse = responses[0];
                const questions = responses[1];

                const region = regionMetaResponse.regions.find(r => r.code === this.props.region);
                if (!region) {
                    throw Error(`Could not find region with code '{this.region}'`);
                }
                const otherRegionsGeometry = regionMetaResponse.regions.filter(r => r !== region)
                    .flatMap(r => r.approxShape.features);
                const bbox = region.bbox;

                // console.log("Loaded question: " + JSON.stringify(questions));

                this.setState({
                    ...this.state,
                    questions: questions,
                    regionMeta: region,
                    otherRegionsGeometry,
                    regionBounds: LngLatBounds.convert([[bbox[0], bbox[1]], [bbox[2], bbox[3]]]),
                    currentQuestion: 0,
                    phase: "ready",
                });
            });
        if (this.props.mode.toLowerCase() === 'droog') {
            this.getRandomDroogImage('droog')
        } else if (this.props.mode.toLowerCase()  === 'nat') {
            this.getRandomDroogImage('nat')
        }
    }

    private setMapLoaded() {
        this.setState({...this.state, mapLoaded: true});
    }

    private onStartClicked() {
        this.setState({
            phase: "answering",
        });
        this.timerRef.current?.reset();
        this.removeStartButton = true;
        this.backend.missionStartPlayThrough(this.props.mode, this.props.region, this.props.missionLevel).then(r => {
            console.log(r);
        });

    }


    private buildAnswerRetriever(): AnswerRetriever {

        return async (q, p) => this.getAnswer(q, p);

    }

    private async getAnswer(questionIndex: number, selectedPosition: Position): Promise<Position> {
        const question = this.state.questions[questionIndex];
        switch (question.type) {
            case QuestionType.RoadObject:
            case QuestionType.FairwayKilometerMark:
            case QuestionType.Hectopunt:
            case QuestionType.PointOfInterest:
                return question.data.position;
            case QuestionType.Road: {
                const geoDataService = GeoDataService.getInstance();
                const distanceResult = await geoDataService.getDroogRoadDistance(question.data.wegnummer, selectedPosition);
                return distanceResult.closestPoint;
            }
            case QuestionType.Fairway: {
                const geoDataService = GeoDataService.getInstance();
                const distanceResult = await geoDataService.getNatFairwayDistance(question.data.id, selectedPosition);
                return distanceResult.closestPoint;
            }
            case QuestionType.PathOfInterest: {
                const geoDataService = GeoDataService.getInstance();
                const distanceResult = await geoDataService.getNatPathOfInterestDistance(question.data.objectKind, question.data.id, selectedPosition);
                return distanceResult.closestPoint;
            }
            case QuestionType.VtsSector: {
                const geoDataService = GeoDataService.getInstance();
                const distanceResult = await geoDataService.getNatVtsSectorDistance(question.data.id, selectedPosition);
                return distanceResult.closestPoint;
            }
        }
    }

    private reset() {
        this.setState({
            ...this.state,
            phase: "loading",
            currentQuestion: 0,
            answerDistances: [],
            answerTimes: [],
            answerScores: [],
            questions: [],
            selectedPosition: null,
        })
        this.removeStartButton = false;
        // TODO implement this in a way that doesn't repeat the code from the "componentDidMount", but also doesn't set the state multiple times
        GeoDataService.getInstance().buildQuestionPromise(this.props.region, this.props.questionConfigs).then(q => {
            this.setState({
                ...this.state,
                questions: q,
                currentQuestion: 0,
                phase: "ready",
            });
        })
    }

    //calculate score
    private calculateScore(distance: number, time: number): number {
        console.log('calc distance: ' + distance);
        if ((time >= this.props.scoreSettings.time.max) ||
            (distance >= this.props.scoreSettings.distance.max)) {
            return 0;
        }

        const maxDistance = this.props.scoreSettings.distance.max;
        const maxTime = this.props.scoreSettings.time.max;

        return MathUtil.map(0, maxDistance, MathUtil.clamp(distance, 0, maxDistance), this.props.scoreSettings.distance.maxScore, 0) +
            MathUtil.map(0, maxTime, MathUtil.clamp(time, 0, maxTime), this.props.scoreSettings.time.maxScore, 0)

    }

    /**
     *
     * @param _
     * @param realDistance The actual distance on the map. This distance may be adjusted when calculating the score
     * @private
     */
    private async onAnswerSelected(_: Position, realDistance: number) {
        console.log('answer selected ')

        let distance = realDistance;
        const time = this.timerRef.current!!.state!!.time;

        //defining error margins based on mission level
        if (this.props.missionLevel === 1) {
            if (distance <= config.mission.errorMargin.levelOne) {
                distance = 0;
            }
        }
        if (this.props.missionLevel === 2) {
            if (distance <= config.mission.errorMargin.levelTwo) {
                distance = 0;
            }
        }
        if (this.props.missionLevel === 3) {
            if (distance <= config.mission.errorMargin.levelThree) {
                distance = 0;
            }
        }
        const score = this.calculateScore(distance, time);
        this.setState({
            ...this.state,
            phase: 'showingAnswer',
            answerDistances: [...this.state.answerDistances, distance],
            answerTimes: [...this.state.answerTimes, time],
            answerScores: [...this.state.answerScores, score]
        })
        this.setObjective(String(time), String(realDistance), score);

        // console.log(this.state);
        await this.missionMapRef.current!!.showAnswerSequence();
    }

    setObjective(time: string, distance: string, score: number): void {
        this.backend.missionSubmitObjective(this.currentQuestion(), score, {
            distance: String(distance),
            time: String(time)
        }).then();
    }

    private onTimerExpired() {
        this.setState({
            ...this.state,
            phase: 'showingAnswer',
            answerDistances: [...this.state.answerDistances, -1],
            answerTimes: [...this.state.answerTimes, this.props.scoreSettings.time.max],
            // The score is always zero if the timer expired:
            answerScores: [...this.state.answerScores, 0],
        });
        // If no answer was given, we calculate the answer based on if the user clicked the centroid of the current region
        const regionCentroid = GeoUtil.centerOfMass(this.state.regionMeta!!.approxShape);
        this.getAnswer(this.state.currentQuestion, regionCentroid.coordinates)
            .then(answerPosition => {
                this.missionMapRef.current?.setAnswerPosition(answerPosition);
                this.missionMapRef.current?.showAnswerSequence();
            });
    }

    private currentQuestion(): Question | null {
        return this.state.questions[this.state.currentQuestion];
    }

    nextQuestion() {
        const lastQuestion = this.state.currentQuestion === this.state.questions.length - 1;
        if (lastQuestion) {
            this.setState({
                ...this.state,
                currentQuestion: -1,
                phase: "savingResult",
            }, () => {
                this.missionEnd()
                  .then(() => this.setState({
                      ...this.state,
                      phase: "complete"
                  }))
                  .catch(e => {
                      alert("Er ging iets mis bij het opslaan van het missie resultaat. Als dit probleem zich blijft voordoen, neem dan contact op.");
                      console.error(e);
                      this.setState({
                          ...this.state,
                          phase: "complete"
                      });
                  })
            });
        } else {

            const nextQuestion = this.state.currentQuestion + 1
            this.setState({
                ...this.state,
                currentQuestion: nextQuestion,
                phase: "answering",
            }, () => {
                this.timerRef.current!!.reset();
                this.missionMapRef.current!!.nextQuestion();
            });

        }
    }

    async missionEnd(): Promise<void> {
        await this.backend.missionEnd(Number(this.state.answerScores.reduce((partial: number, a: number) => partial + a, 0).toFixed(0)), this.calculateMaxScore())
    }

    // noinspection JSMethodCanBeStatic
    private calculateMaxScore(): number {
        return config.mission.scoring.totalMaxScore;
    }

    renderExplanationContent(mouse: boolean, game: boolean) {
        this.setState({
            mouse: mouse,
            game: game
        })
    }
    determinePopupState(popup:boolean) {
        this.setState({
            popupOpen: popup
        })
    }
    closePopup() {
        this.setState({
            popupOpen: false
        });
    }
    renderCurrentPhase(): ReactNode {
        switch (this.state.phase) {
            case 'loading':
                return <Loading text="Missie laden..." />;
            case 'savingResult':
                return <Loading text="Resultaat opslaan..." />;
            case 'ready':
            case 'answering':
            case 'showingAnswer':
                return (
                    <div className="w-full h-full flex flex-col">
                        <div
                            className={`start-button-map grid z-10 place-items-center h-screen absolute ${this.removeStartButton ? 'remove-start-button' : ''}`}>

                            <div>
                                <div className="background">
                                    <img src={`${this.state.backgroundImage}`} alt="background"/>
                                </div>
                                <div className="illustration">
                                    <img src={blob} alt="blob"/>
                                </div>
                                {sessionStorage.getItem('returnUrl') ?
                                    // <MissionQuitButton/>
                                    <>
                                        <a href={sessionStorage.getItem('returnUrl') || ''}>
                                        <div style={{position: 'absolute', top: '50px', right: '50px'}} className="hamburger-closed mission-closed-button">
                                            <div className="burger-slices">
                                                <span className="line line-1"></span>
                                                <span className="line line-2"></span>
                                                <span className="line line-3"></span>
                                            </div>
                                        </div>
                                        </a>
                                    </>
                                    :
                                    <PreviousButton previousUrl={'./../'}/>
                                }
                                <div className="uitleg">
                                    <h1 className="mt-16 heading w-full text-center">
                                        Uitleg gameplay
                                    </h1>
                                    <div className="sub-heading">
                                        Categorie: {this.props.mode + ' > Regio: ' + this.state.regionMeta?.name + ' > Niveau ' + this.props.missionLevel}
                                    </div>
                                    <div>
                                        {this.props.mode === MissionMode.Droog ?
                                            <>
                                                <p>
                                                    Het doel van Locatiespotter is om gevraagde objecten binnen je regio
                                                    te vinden.<br/><br/>
                                                    <b>
                                                        In elke missie worden 10 objecten gevraagd, die je op de kaart
                                                        moet aanklikken.<br/><br/>
                                                    </b>
                                                    Hoe dichter je bij een object klikt en hoe sneller je klikt, hoe
                                                    meer punten je krijgt! Bij meer punten, krijg je meer sterren.
                                                    <br/><u>Je hebt minimaal 1 ster nodig om naar het volgende niveau te
                                                    mogen.</u><br/><br/>

                                                    Tip: Je kan je muiswiel gebruiken om in- en uit te zoomen. </p>
                                            </>
                                            :
                                            <>
                                                <p>
                                                    Het doel van Locatiespotter is om gevraagde objecten binnen je regio
                                                    te vinden.<br/><br/>

                                                    <b>In elke missie worden 10 objecten gevraagd, die je op de kaart
                                                        moet aanklikken.</b><br/><br/>

                                                    Hoe <b>dichter</b> je bij een object klikt en hoe sneller je klikt,
                                                    hoe meer punten je krijgt! Bij meer punten, krijg je meer
                                                    sterren.<br/>

                                                    <u>Je hebt minimaal 1 ster nodig om naar het volgende niveau te
                                                        mogen.</u><br/><br/>

                                                    Tip: Je kan je muiswiel gebruiken om in- en uit te zoomen.
                                                </p>
                                            </>}
                                    </div>

                                </div>
                                <div className="explain-buttons">
                                    <div className="next-button row-button" onClick={()=>{this.renderExplanationContent(false, false)}}>
                                        <svg width="39" height="45" viewBox="0 0 39 45" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M39 22.5L0.750002 44.5836L0.750004 0.416352L39 22.5Z" fill="black"/>
                                        </svg>
                                        <button onClick={() => this.onStartClicked()} disabled={!this.state.mapLoaded}
                                                className="title">
                                            {this.state.mapLoaded ? "START GAME" : "Laden..."}
                                        </button>
                                    </div>
                                    <div className="next-button row-button button-blue" onClick={()=>{this.renderExplanationContent(true, false); this.determinePopupState(true)}}>
                                        <svg width="39" height="51" viewBox="0 0 39 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_736_4246)">
                                                <path d="M22.525 0H16.0893C7.20297 0 0 7.13402 0 15.9375V35.0625C0 43.866 7.20297 51 16.0893 51H22.525C31.4123 51 38.6143 43.865 38.6143 35.0625V15.9375C38.6143 7.13402 31.4143 0 22.525 0ZM33.7875 15.9375V17.5312H21.7205V4.78125H22.525C28.7495 4.7932 33.7774 9.77367 33.7875 15.9375ZM16.0893 4.78125H16.8937V17.5312H4.82679V15.9375C4.83986 9.77367 9.86776 4.7932 16.0893 4.78125ZM22.525 46.2188H16.0893C9.86675 46.2063 4.83684 41.2253 4.82679 35.0625V22.3125H33.7875V35.0625C33.7774 41.2283 28.7495 46.2088 22.525 46.2188Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_736_4246">
                                                    <rect width="38.6143" height="51" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <button disabled={!this.state.mapLoaded}
                                                className="title">
                                            {this.state.mapLoaded ? "MUIS GEBRUIK" : "Laden..."}
                                        </button>
                                    </div>
                                    <div className="next-button button-blue row-button" onClick={()=>{this.renderExplanationContent(false, true);this.determinePopupState(true)}}>
                                        <svg width="51" height="46" viewBox="0 0 51 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_736_4251)">
                                                <path d="M45.3333 0H5.66667C2.53672 0 0 2.53672 0 5.66667V31.1667C0 34.2966 2.53672 36.8333 5.66667 36.8333H18.7974L17.9474 41.0833H13.4583C12.2896 41.0833 11.3333 42.0396 11.3333 43.2083C11.3333 44.3771 12.2896 45.3333 13.4583 45.3333H37.5417C38.7148 45.3333 39.6667 44.3815 39.6667 43.2083C39.6667 42.0352 38.7148 41.0833 37.5417 41.0833H33.0499L32.2026 36.8333H45.3333C48.4633 36.8333 51 34.2966 51 31.1667V5.66667C51 2.53672 48.4589 0 45.3333 0ZM28.7141 41.0833H22.2859L23.1359 36.8333H27.8694L28.7141 41.0833ZM46.75 31.1667C46.75 31.9478 46.1144 32.5833 45.3333 32.5833H5.66667C4.88555 32.5833 4.25 31.9478 4.25 31.1667V26.9167H46.75V31.1667ZM46.75 22.6667H4.25V5.66667C4.25 4.88555 4.88555 4.25 5.66667 4.25H45.3333C46.1144 4.25 46.75 4.88555 46.75 5.66667V22.6667Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_736_4251">
                                                    <rect width="51" height="45.3333" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <button disabled={!this.state.mapLoaded}
                                                className="title">
                                            {this.state.mapLoaded ? "GAME SCHERM" : "Laden..."}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="popup">
                                <div className={this.state.popupOpen ? 'tools': 'dont-show-tools'}>
                                    <div className="closePopup" onClick={() => this.closePopup()}>
                                        <img src={closePopup} alt="close popup"/>
                                    </div>
                                </div>
                                <ExplanationPopup open={this.state.popupOpen} game={this.state.game} mouse={this.state.mouse}/>
                            </div>
                        </div>
                        <div id="mission-header" className="w-full h-auto flex flex-row">
                            <div id="mission-progress-container" className="flex-grow">
                                <QuestionProgressIndicator totalQuestions={this.state.questions.length}
                                                           currentQuestion={this.state.currentQuestion}/>
                                <QuestionTimer
                                    ref={this.timerRef}
                                    totalTime={this.props.scoreSettings.time.max}
                                    paused={this.state.phase !== 'answering'}
                                    colorMap={[
                                        {percentage: 0, color: "#248D4C"},
                                        {percentage: 0.5, color: "#F6CD3A"},
                                        {percentage: 0.7, color: "#F6943A"},
                                        {percentage: 1, color: "#F63A3A"}
                                    ]}
                                    pausedColor={"#174374"}
                                    timerExpired={() => this.onTimerExpired()}
                                />
                            </div>
                            <div id="feedback-button-container" className="flex items-center m-1">
                                {/*<HamburgerGameMenu/>*/}

                                {sessionStorage.getItem('returnUrl') ?
                                    // <MissionQuitButton/>
                                    <>
                                        <a href={sessionStorage.getItem('returnUrl') || ''}>
                                            <div style={{position: 'absolute', top: '50px', right: '50px'}} className="hamburger-closed mission-closed-button">
                                                <div className="burger-slices">
                                                    <span className="line line-1"></span>
                                                    <span className="line line-2"></span>
                                                    <span className="line line-3"></span>
                                                </div>
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <MissionQuitButton />
                                }
                            </div>
                        </div>
                        <div id="mission-map-container" className="w-full flex-grow">
                            <span>
                            <MissionMap
                                ref={this.missionMapRef}
                                regionBounds={this.state.regionBounds}
                                onAnswerSelected={(a, d) => this.onAnswerSelected(a, d)}
                                answerRetriever={this.buildAnswerRetriever()}
                                mode={this.props.mode}
                                otherRegionOverlayGeometry={this.state.otherRegionsGeometry}
                                onLoad={() => this.setMapLoaded()}
                            />
                            </span>
                            <MissionScoreBanner score={this.state.answerScores} missionLevel={this.props.missionLevel} regionName={this.state.regionMeta?.name ?? this.props.region}/>
                            <div className="doel-card">
                                <h2 className="text-lg font-bold">Zoek naar:</h2>
                                <div className="line"/>
                                {QuestionSign(this.currentQuestion()!!, "large")}
                            </div>
                            {this.state.phase === 'showingAnswer' ?
                                (
                                    <div className={'showing-answer'}>
                                        <div className="doel-card">
                                            <div className="title">
                                                <img className="pin" src={pinResult} alt="pin result icon"/>
                                                <h2 className="text-lg font-bold">Doel:</h2>
                                            </div>
                                            <div className="line"/>
                                            {QuestionSign(this.currentQuestion()!!, "large")}
                                            <div className="title mt-10">
                                                <img className="pin" src={pin} alt="pin result icon"/>
                                                <h2 className="text-lg font-bold">Resultaat: </h2>
                                            </div>
                                            <div className="line"/>
                                            <QuestionResult
                                                distance={this.state.answerDistances[this.state.currentQuestion]}
                                                time={this.state.answerTimes[this.state.currentQuestion]}
                                                score={this.state.answerScores[this.state.currentQuestion]}
                                            />
                                        </div>
                                        <div onClick={_ => this.nextQuestion()} className="next-button">
                                            <svg width="39" height="45" viewBox="0 0 39 45" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M39 22.5L0.750002 44.5836L0.750004 0.416352L39 22.5Z"
                                                      fill="black"/>
                                            </svg>
                                            <div className="title">VOLGENDE</div>
                                        </div>
                                    </div>
                                )
                                : null
                            }
                        </div>
                    </div>
                );
            case 'complete':
                return (<MissionResult
                    questions={this.state.questions}
                    missionMode={this.props.mode}
                    missionLevel={this.props.missionLevel}
                    missionRegion={this.props.region}
                    answerDistances={this.state.answerDistances}
                    answerTimes={this.state.answerTimes}
                    answerScores={this.state.answerScores}
                    onRestartClicked={() => this.reset()}/>)
        }
    }

    render(): ReactNode {
        return (
            <div className="w-full h-full inline-block relative">
                {this.renderCurrentPhase()}
            </div>
        );
    }

}

