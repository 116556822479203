import React from "react";
import {PreviousButton} from "../components/PreviousButton";
import '../styles/_support.scss';
import blob from "../img/blob-top-right.svg";
export class SupportPage extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div className="illustration">
                    <img src={blob} alt="blob"/>
                </div>
                <PreviousButton previousUrl={'./../'}/>
                <div className="support">
                    <h1>Support</h1>
                    <h2 className={'mb-4'}>Hulp nodig?</h2>
                    <p>Locatiespotter is bedoeld voor het testen en vergroten van je objectenkennis. We hebben er naar gestreefd om het spel (technisch) zo compleet mogelijk te maken.</p>
                    <p>Indien je vragen hebt, fouten (bugs) of andere vormen van feedback wil doorgeven kan dat via dit formulier: <a target={'_blank'} href={'https://forms.clickup.com/f/4hfzg-5041/47P3EAIF5R0GT30VFW'}>Feedback verzenden</a> of door een e-mail te sturen naar <a href={'mailto:support@gametailors.com'}> support@gametailors.com</a></p>
                </div>
            </div>
        );
    }
}