import React, {RefObject} from 'react';
import {GeoDataService} from '../data/GeoDataService';
import {RegionMeta} from '../data/GeoData';
import {MissionMode} from '../Mission';
import {NavigateFunction} from 'react-router-dom';
import {PreviousButton} from '../components/PreviousButton';
import Map, {Layer, MapRef, Marker, Source} from 'react-map-gl';
import '../styles/_regions.scss';
import {ConfigService} from '../config/ConfigService';
import {GeoUtil} from '../util/GeoUtil';
import withNavigate from '../util/WithNavigate';
import blob from '../img/blob-top-right.svg'

interface RegionSelectionProps {
    mode: MissionMode,
    navigate: NavigateFunction,
}

interface RegionSelectionState {
    regions: RegionMeta[];
    hoveringRegionCode: string | null;
}

class RegionSelection extends React.Component<RegionSelectionProps, RegionSelectionState> {

    private readonly mapRef!: RefObject<MapRef>;

    constructor(props: RegionSelectionProps) {
        super(props);

        this.mapRef = React.createRef<MapRef>();

        this.state = {
            regions: [],
            hoveringRegionCode: null,
        }
    }

    async componentDidMount() {


    }

    async displayRegions() {
        const geoDataService = GeoDataService.getInstance();
        const regionsResponse = this.props.mode === MissionMode.Nat ? await geoDataService.getNatRegions() : await geoDataService.getDroogRegions();

        this.mapRef.current?.setZoom(6.4);
        this.setState({
            regions: regionsResponse.regions,
        }, () => {
            for(const region of this.state.regions) {
                this.mapRef.current!!.on('mousemove', this.fillLayerId(region), _ => this.onMouseEnter(region));
                this.mapRef.current!!.on('mouseleave', this.fillLayerId(region), _ => this.onMouseLeave(region));
                this.mapRef.current!!.on('click', this.fillLayerId(region), _ => this.selectRegion(region));
            }
        });
    }

    onMouseEnter(region: RegionMeta) {
        this.setHover(region, true);
        this.setState({...this.state, hoveringRegionCode: region.code});
    }

    private onMouseLeave(region: RegionMeta) {
        this.setState({...this.state, hoveringRegionCode: null});
        this.setHover(region, false);
    }

    private setHover(region: RegionMeta, hover: boolean) {
        region.approxShape.features.forEach((_, i) => {
            this.mapRef.current?.setFeatureState(
                {source: this.sourceId(region), id: i},
                {hover}
            );
        });
    }

    private selectRegion(region: RegionMeta) {
        this.props.navigate(region.code.replaceAll(/\s+/g, "-") + "/difficulty");
    }

    fillLayerId(region: RegionMeta) {
        return region.code + "-fill"
    }

    sourceId(region: RegionMeta) {
        return region.code;
    }

    render() {

        return (
            <div className={'region-selection'}>
                <div className="illustration">
                    <img src={blob} alt="blob"/>
                </div>
                <PreviousButton previousUrl={'./../'}/>
                <h1 className="mt-16 heading w-full text-center">Kies een regio</h1>
                <h3 className="sub-heading">Categorie: {this.props.mode}</h3>

                <div className="map regions-selection-map">

                    <Map
                        reuseMaps
                        onLoad={() => this.displayRegions()}
                        ref={this.mapRef}
                        initialViewState={{
                            longitude: 5.2913,
                            latitude: 52.1326,
                            zoom: 6.4
                        }}
                        mapboxAccessToken={ConfigService.getInstance().getMapboxAccessToken()}
                        mapStyle={ ConfigService.getInstance().getMapboxStyleRegionSelect() }
                        style={{
                            height: "600px",
                            width: "800px",
                            position: "relative",
                        }}
                        cursor={'pointer'}
                        dragPan={false} dragRotate={false} doubleClickZoom={false} boxZoom={false} scrollZoom={false} touchPitch={false} keyboard={false} touchZoomRotate={false}
                    >
                        {
                            this.state.regions.map(r => {
                                return <Source key={r.code} type='geojson' id={this.sourceId(r)} data={r.approxShape} generateId={true}>
                                    <Layer id={this.fillLayerId(r)} source={r.code} type="fill" paint={{
                                        'fill-color': '#026FD4',
                                        'fill-opacity': [
                                            'case',
                                            ['boolean', ['feature-state', 'hover'], false],
                                            1,
                                            0
                                        ]
                                    }}
                                    />
                                    <Layer
                                        id={r.code + '-line'}
                                        type="line"
                                        source={r.code}
                                        paint={{
                                            "line-width": 1,
                                            "line-color": "#fff"
                                        }} />
                                    <Marker longitude={GeoUtil.centerOfMass(r.approxShape).coordinates[0]}
                                            latitude={GeoUtil.centerOfMass(r.approxShape).coordinates[1]}
                                            anchor={'center'}
                                            style={{fontWeight: 'bold', color: 'black', fontSize:'20px',
                                                visibility: this.state.hoveringRegionCode === r.code ? 'visible' : 'hidden'}}
                                    >
                                        {r.name}
                                    </Marker>
                                </Source>
                            })
                        }
                    </Map>

                </div>
            </div>)
    }
}

export default withNavigate(RegionSelection);
