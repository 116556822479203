import React from "react";
import '../styles/_missionScoreBanner.scss';
import config from '../config.json'
import {FormatUtil} from "../util/FormatUtil";
interface MissionScoreBannerProps {
    regionName: string,
    missionLevel: number,
    score: number[];
}
interface MissionScoreBannerState {
    progress: number
}
export class MissionScoreBanner extends React.Component<MissionScoreBannerProps, MissionScoreBannerState> {

    constructor(props: MissionScoreBannerProps) {
        super(props);
        this.state = {
            progress: 0
        }
    }



    componentDidUpdate(prevProps: Readonly<MissionScoreBannerProps>, prevState: Readonly<MissionScoreBannerState>, snapshot?: any) {
        if (prevProps.score !== this.props.score) {
            this.setProgress()
        }
    }

    setProgress():void {
        let maxScore = config.mission.scoring.totalMaxScore;
       let currentScore = this.props.score.reduce((partialSum, a) => partialSum + a, 0).toFixed(0)
       let percentage = (100 * Number(currentScore)) / maxScore
        this.setState({
            progress: percentage
        })
    }

    render() {
        return (
            <div>
                <div className="score-banner">
                    <div className="regio">
                        {'Regio: ' + this.props.regionName + ' | Niveau: ' + this.props.missionLevel}
                    </div>
                    <div className="score-stars">
                        <div className="title">
                            <b>Score: {FormatUtil.formatScore(Number(this.props.score.reduce((partialSum, a) => partialSum + a, 0).toFixed(0)))}</b>
                        </div>
                        <div className="progress-bar">
                            <div className="progress" style={{width: `${this.state.progress}%`}}></div>
                            <div className="baseline"></div>
                            <div className="stars">
                                <div className="star-item">
                                    <div className="line"></div>
                                    <div className="star">
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.5 0L7.95934 4.49139H12.6819L8.86126 7.26722L10.3206 11.7586L6.5 8.98278L2.6794 11.7586L4.13874 7.26722L0.318133 4.49139H5.04066L6.5 0Z" fill="#F9C306"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="star-item">
                                    <div className="line"></div>
                                    <div className="star">
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.5 0L7.95934 4.49139H12.6819L8.86126 7.26722L10.3206 11.7586L6.5 8.98278L2.6794 11.7586L4.13874 7.26722L0.318133 4.49139H5.04066L6.5 0Z" fill="#F9C306"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="star-item">
                                    <div className="line"></div>
                                    <div className="star">
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.5 0L7.95934 4.49139H12.6819L8.86126 7.26722L10.3206 11.7586L6.5 8.98278L2.6794 11.7586L4.13874 7.26722L0.318133 4.49139H5.04066L6.5 0Z" fill="#F9C306"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
