import React from 'react';
import '../styles/_welcome.scss';
import {Link} from 'react-router-dom';
import {BackendService} from '../data/BackendService';
import {FormatUtil} from "../util/FormatUtil";

interface WelcomeStartState {
    playerSum?: number,
    nickname?: string
}

interface WelcomeStartProps {
}

export class WelcomeStart extends React.Component<WelcomeStartProps, WelcomeStartState> {
    private backend = BackendService.getInstance();

    constructor(props: WelcomeStartProps) {
        super(props);
        this.state = {
            playerSum: 0,
            nickname: this.backend.loggedInPlayer.getValue()?.nickname,
        }
    }
    async getPlayerStats() {
        await this.backend.getPlayerMe().then(p => {
             this.backend.getHighscoreModeSum('droog').then(dr => {
                this.backend.getHighscoreModeSum('nat').then(na => {
                    let nat = na.highscores.find(hs => hs.score && hs.playerUid === p.uid);
                    let droog = dr.highscores.find(hs => hs.score && hs.playerUid === p.uid);
                    if (!droog) {
                        droog ={score: 0, playerUid: '', playerNickname: ''}
                    }
                    if (!nat) {
                        nat = {score: 0, playerUid: '', playerNickname: ''}
                    }
                        this.setState({
                            playerSum: droog!.score + nat!.score,
                            nickname: p.nickname,
                        });
                })
            })
        })
    }
    componentDidMount() {
        this.backend.getPlayerMe().then();
        this.getPlayerStats().then();
    }

    render() {
        return (
            <div>
                <div className="welcome">
                    <div className="title">
                        Welkom{this.state.nickname ? ', ' : ''}<b>{this.state.nickname ? this.state.nickname : ''}</b>
                    </div>
                    <div className="title">
                        Je hebt tot nu toe <b>{FormatUtil.formatScore(this.state.playerSum || 0)}</b> punten behaald.
                    </div>
                    <div className="title flex">
                        {/*<span className="mr-2">Je staat nu  </span>*/}
                        {/*<div className="rang">10</div>*/}
                        {/*<div>e binnen Rijkswaterstaat.</div>*/}
                    </div>
                    <div className="title title-link">
                        <Link to={'score'}>Kijk hier voor meer scores.</Link>
                    </div>
                </div>
            </div>
        );
    }
}
