import React from "react";
import {BackendService, Highscore} from "../data/BackendService";
import {FormatUtil} from "../util/FormatUtil";

export interface LeaderboardContentProps {
    mode: string
}

export interface LeaderboardContentState {
    totalHighscores?: Highscore[],
    averageHighscores?: Highscore[],
    playerNickname: string,
    playerUid: string,
    score: number,
    currentPlayerUid: string,
    currentPlayerScore: number,
    currentPlayerPosition?: number,
    currentPlayerNickname: string
    currentPlayerAvg: number
}

export class LeaderboardContent extends React.Component<LeaderboardContentProps, LeaderboardContentState> {
    private backend = BackendService.getInstance();

    constructor(props: LeaderboardContentProps) {
        super(props);
        this.getLeaderboardData()
        this.state = {
            playerNickname: '',
            playerUid: '',
            score: 0,
            currentPlayerNickname: '',
            currentPlayerScore: 0,
            currentPlayerUid: '',
            currentPlayerAvg: 0,
        }
    }
    getLeaderboardData() {
        this.backend.getHighscoreModeSum(this.props.mode)
            .then(r => {
                this.setState({...this.state, totalHighscores: r.highscores});
                this.backend.getHighscoreModeAvg(this.props.mode, r.highscores.map(h => h.playerUid))
                    .then(r => {
                        this.setState({...this.state, averageHighscores: r.highscores})
                    })
            });

        this.backend.getPlayerMe(true).then(p => {
            const totalScorePromise = this.backend.getHighscoreModeSum(this.props.mode, [p.uid]);
            const averageScorePromise = this.backend.getHighscoreModeAvg(this.props.mode, [p.uid]);
            Promise.all([totalScorePromise, averageScorePromise])
                .then(([total, average]) => {
                    this.setState({
                        ...this.state,
                        currentPlayerUid: p.uid,
                        currentPlayerNickname: p.nickname,
                        currentPlayerPosition: total.playerPosition,
                        currentPlayerAvg: average.highscores.length > 0 ? average.highscores[0].score : 0,
                        currentPlayerScore: total.highscores.length > 0 ? total.highscores[0].score : 0
                    });
                })
        });
    }

    componentDidUpdate(prevProps: Readonly<LeaderboardContentProps>, prevState: Readonly<LeaderboardContentState>, snapshot?: any) {
        if (prevProps.mode !== this.props.mode) {
            this.getLeaderboardData()
        }
    }

    renderStats() {
        if (this.state.totalHighscores) {
            return this.state.totalHighscores.map((stats, index) => {
                const avg = this.state.averageHighscores?.find(h => h.playerUid === stats.playerUid)
                return (
                    <div className="played" key={index}>
                        <div className="ranking-container"><div className="ranking" key={"idx_" + index}>{index + 1}</div></div>
                        <div className={'name'} key={"name_" + stats.playerUid}>{stats.playerNickname}</div>
                        <div className="score" key={stats.score}>{FormatUtil.formatScore(stats.score)}</div>
                        <div className="score" key={"avg_"  + stats.playerUid}>{avg ? FormatUtil.formatScore(avg.score)+ '' : "-" }</div>
                    </div>
                )
            })
        }


    }

    renderYou() {
        if (this.state.currentPlayerAvg) {
            return (
                <div>
                    <div className="played">
                        <div className="ranking-container"><div className="ranking">{this.state.currentPlayerPosition ?? '-'}</div></div>
                        <div className={'name'}>Jij</div>
                        <div className="score">{FormatUtil.formatScore(this.state.currentPlayerScore) + ''}</div>
                        <div className="score">{FormatUtil.formatScore(this.state.currentPlayerAvg) + ' '}</div>
                    </div>
                </div>
            );
        } else {
            return ('')
        }
    }

    render() {
        return (
            <div>
                <section className={this.props.mode === 'droog' ? 'leaderboard-droog' : 'leaderboard-nat'}>
                    <div className="lb-inner">
                        <div className="score-content">
                            <div className="played-before">
                                <div className="category-titles">
                                    <div className="ranking-container"></div>
                                    <div className="name"></div>
                                    <div className="title">Totale score</div>
                                    <div className="title">Gemiddelde score</div>
                                </div>
                                <div className="played-before played-active">
                                    {this.renderYou()}
                                </div>
                                {this.renderStats()}
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
