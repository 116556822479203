import React, {ReactNode} from "react";
import {Link} from "react-router-dom";
import {PreviousButton} from "../components/PreviousButton";
import '../styles/_difficulty.scss';
import config from '../config.json';
import {BackendService} from "../data/BackendService";
import {FormatUtil} from "../util/FormatUtil";
import blob from "../img/blob-top-right.svg";
import lockButton from '../img/lock-button.png'
import replay from '../img/replay.png'
import newButton from '../img/new-button.png'
import Stars from "../components/Stars";
import {RegionMeta} from "../data/GeoData";
import {GeoDataService} from "../data/GeoDataService";

interface DifficultyProps {
    region: string
    mode: string
}

interface DifficultyState {
    secondStageLocked: boolean
    thirdStageLocked: boolean
    nickname: string
    uid: string
    regionMeta?: RegionMeta
    progress: {
        missionProgress: [
            {
                missionUid: string
                score: number
            }
        ]
    }
    levelOneScore: number
    levelTwoScore: number
    levelThreeScore: number
}

export class Difficulty extends React.Component<DifficultyProps, DifficultyState> {
    private backend = BackendService.getInstance();

    constructor(props: DifficultyProps) {
        super(props);
        this.state = {
            nickname: "", uid: "",
            progress: {missionProgress: [{missionUid: "", score: 0}]},
            secondStageLocked: true,
            thirdStageLocked: true,
            levelOneScore: 0,
            levelTwoScore: 0,
            levelThreeScore: 0
        }
        const geoData = GeoDataService.getInstance();
        const regionMetaPromise = this.props.mode === "droog" ? geoData.getDroogRegions() : geoData.getNatRegions();
        regionMetaPromise
          .then(r => {
              const regionMeta = r.regions.find(r => r.code === this.props.region);
              if (regionMeta) this.setState({...this.state, regionMeta});
          });
    }

    renderSecondStage(): ReactNode {
        switch (this.state.secondStageLocked) {
            case true:
                return (
                    <div className={'locked'}>
                        <Link to={''}>
                            <div className="difficulty-inner">
                                <div className={'content flex items-center'}>
                                    <div className="title mr-20">
                                        <h2>Niveau 2</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="description">{this.renderDifficultyDescriptionBasedOnMode(2)}</div>
                                </div>
                                <div className={'flex items-center'}>
                                    <div className=" ml-4">
                                        <div className=" ml-4">
                                            <img src={lockButton} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            case false:
                return (
                    <div>
                        {this.state.levelOneScore >= config.mission.stars.oneStar &&
                        this.state.levelOneScore < config.mission.stars.twoStars &&
                        !(this.state.levelTwoScore > 0) &&
                        !(this.state.levelThreeScore > 0) ?
                            <div className="new">
                                Nieuw!
                            </div>
                            :
                            ''
                        }
                        <Link to={'2'}>
                            <div className="difficulty-inner">
                                <div className={'flex items-center'}>
                                    <div className="title mr-20">
                                        <h2>Niveau 2</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="description">{this.renderDifficultyDescriptionBasedOnMode(2)}</div>
                                </div>
                                <div className={'flex items-center'}>
                                    <div className="high-score mr-5">
                                        <div>Highscore</div>
                                        <div>{FormatUtil.formatScore(this.state.levelTwoScore)}</div>
                                    </div>
                                    <div className="goal-score">
                                        Score
                                        <div className="stars">
                                            <Stars score={this.state.levelTwoScore} />
                                        </div>
                                    </div>
                                    <div className=" ml-4">
                                        {
                                           this.state.levelTwoScore > 0 ?
                                               <img src={replay} alt="replay button"/>
                                                :
                                               <img src={newButton} alt="new button"/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
        }
        return (
            <div></div>
        )
    }

    renderThirdStage(): ReactNode {
        switch (this.state.thirdStageLocked) {
            case true:
                return (
                    <div className={'locked'}>
                        <Link to={''}>
                            <div className="difficulty-inner">
                                <div className={'content flex items-center'}>
                                    <div className="title mr-20">
                                        <h2>Niveau 3</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="description">{this.renderDifficultyDescriptionBasedOnMode(3)}
                                    </div>
                                </div>
                                <div className="">
                                    <div className=" ml-4">
                                        <img src={lockButton} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            case false:
                return (
                    <div className={'three-inner'}>
                        {
                            this.state.levelTwoScore >= config.mission.stars.oneStar
                            && this.state.levelTwoScore < config.mission.stars.twoStars
                            && !(this.state.levelThreeScore > 0)
                                ?
                                <div className="new">
                                    Nieuw!
                                </div>
                                :
                                ''
                        }
                        <Link to={'3'}>
                            <div className="difficulty-inner">
                                <div className={'flex items-center'}>
                                    <div className="title mr-20">
                                        <h2>Niveau 3</h2>
                                        <div className="line"/>
                                    </div>
                                    <div className="description">{this.renderDifficultyDescriptionBasedOnMode(3)}
                                    </div>
                                </div>
                                <div className={'flex items-center'}>
                                    <div className="high-score mr-5">
                                        <div>Highscore</div>
                                        <div>{FormatUtil.formatScore(this.state.levelThreeScore)}</div>
                                    </div>
                                    <div className="goal-score">
                                        Score
                                        <div className="stars">
                                            <Stars score={this.state.levelThreeScore} />
                                        </div>
                                    </div>
                                    <div className=" ml-4">
                                        {
                                            Number(this.state.levelThreeScore) > 0 ?
                                                <img src={replay} alt="replay button"/>
                                                :
                                                <img src={newButton} alt="new button"/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
        }
    }

    determineLockedStages(): void {

        if (this.state.levelOneScore < config.mission.stars.oneStar) {
            this.setState({
                secondStageLocked: true
            });
        }
        if (this.state.levelOneScore >= config.mission.stars.oneStar) {
            this.setState({
                secondStageLocked: false
            });
        }
        if (this.state.levelTwoScore < config.mission.stars.oneStar) {
            this.setState({
                thirdStageLocked: true
            });
        }
        if (this.state.levelTwoScore >= config.mission.stars.oneStar) {
            this.setState({
                thirdStageLocked: false
            });
        }
    }

    componentDidMount() {
        this.getPlayerMe();
    }

    getPlayerMe() {
        let mode: string;
        let region: string;
        mode = this.props.mode === 'droog' ? 'D' : 'N';
        region = this.props.region.replace(' ', '-');
        this.backend.getPlayerMe().then(r => {
            // console.log("PlayerMe", r)

            const levelOneMissionUid = this.backend.determineMissionUid(mode, region, 1);
            const levelTwoMissionUid = this.backend.determineMissionUid(mode, region, 2);
            const levelThreeMissionUid = this.backend.determineMissionUid(mode, region, 3);

            this.setState({
                ...this.state,
                levelOneScore: r.progress.missionProgress.find(p => p.missionUid === levelOneMissionUid)
                    ?.score ?? 0,
                levelTwoScore: r.progress.missionProgress.find(p => p.missionUid === levelTwoMissionUid)
                    ?.score ?? 0,
                levelThreeScore: r.progress.missionProgress.find(p => p.missionUid === levelThreeMissionUid)
                    ?.score ?? 0
            }, () => {
                this.determineLockedStages()
            })
        });
    }

    renderDifficultyDescriptionBasedOnMode(level: number) {
        if (this.props.mode === 'droog') {
            switch (level) {
                case 1:
                    return 'A-wegen en N-wegen met 2 cijfers'
                case 2:
                    return 'Bruggen, tunnels en knooppunten'
                case 3:
                    return 'Hectometer paaltjes en N-wegen met 3 cijfers'
            }
        } else if (this.props.mode === 'nat') {
            switch (level) {
                case 1:
                    // Fairways, bruggen, radiocallinpoints, sluizen
                    return 'Vaarwegen, bruggen, sluizen en kunstwerkcomplexen'
                case 2:
                    return 'Ligplaatsen, trailerhellingen en havens'
                case 3:
                    return 'Kilometermarkeringen en marifoonkanalen'
            }
        } else {
            return 'There was an error while loading the description'
        }

    }

    render() {
        return (
            <div className={'difficulty h-full w-full inline-block relative'}>
                <PreviousButton previousUrl={'/mode/' + this.props.mode}/>
                <div className="illustration">
                    <img src={blob} alt="blob"/>
                </div>
                <h1 className="mt-16 heading w-full tex-center">
                    Kies een niveau
                </h1>
                <h3 className="sub-heading">Categorie: {(this.props.mode === 'droog' ? 'Droog' : 'Nat') + ' > Regio: ' + this.state.regionMeta?.name ?? this.props.region}</h3>
                <div className="difficulties">
                    <div className="one">
                        <Link to={'1'}>
                            {!(this.state.levelOneScore > 0) ?
                                <div className="new">
                                    Nieuw!
                                </div>
                                :
                                ''
                            }
                            <div className="difficulty-inner">
                                <div className={'flex items-center'}>
                                    <div className="title mr-20">
                                        <h2>Niveau 1</h2>
                                        <div className="line"></div>
                                    </div>
                                    <div className="description">{this.renderDifficultyDescriptionBasedOnMode(1)}</div>
                                </div>
                                <div className={'flex items-center'}>
                                    <div className="high-score mr-5">
                                        <div>Highscore</div>
                                        <div>{FormatUtil.formatScore(this.state.levelOneScore)}</div>
                                    </div>
                                    <div className="goal-score">
                                        Score
                                        <div className="stars">
                                            <Stars score={this.state.levelOneScore} />
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        {
                                            this.state.levelOneScore > 0 ?
                                                <img src={replay} alt="replay button"/>
                                                :
                                                <img src={newButton} alt="new button"/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="two">
                        {this.renderSecondStage()}
                    </div>
                    <div className="three">
                        {this.renderThirdStage()}
                    </div>
                </div>
            </div>
        )
    }
}
