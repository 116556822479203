import React from "react";
import '../styles/_hamburger.scss';
import {Link} from "react-router-dom";
import {BackendService} from "../data/BackendService";

interface HamburgerMenuProps {
    isStart: boolean
}

interface HamburgerMenuState {
    hamburger: boolean
}

export class HamburgerMenu extends React.Component<HamburgerMenuProps, HamburgerMenuState> {
    private backend = BackendService.getInstance();
    hamburgerToggle = false;
    handleHamburgerToggle = () => {
        this.setState({hamburger: this.hamburgerToggle})
        this.hamburgerToggle = !this.hamburgerToggle;
    }
    logout(){
        sessionStorage.removeItem('tokenValidUntil')
        sessionStorage.removeItem('userSession')
        sessionStorage.removeItem('userUid')
        sessionStorage.removeItem('sessionLastActive')
        sessionStorage.removeItem('token')
        window.location.href = '/';
    }
    render() {
        return (<div className={`hamburger z-20 ${this.hamburgerToggle ? 'expand-width' : ''}`}>
            <div className="burger-slices" onClick={this.handleHamburgerToggle}>
                <span className="line line-1"/>
                <span className="line line-2"/>
                <span className="line line-3"/>
            </div>
            <div className="hamburger-inner">
                <ul>
                    <li  className={'audio'}>Audio <label className="switch">
                        <input type="checkbox" />
                            <span className="slider"></span>
                    </label></li>
                    {this.props.isStart ? <>
                            <li> <Link to={'../../../../info'} > Info </Link></li>
                            <li> <Link to={'../../../../support'} > Support </Link></li>
                            <li> <Link to={'../tips'} > Tips </Link></li>
                            <li onClick={()=> this.logout()} > Logout </li>
                        </>
                        : <>
                            <li> <Link to={'tips'} > Tips </Link></li>
                            <li> <Link to={'./../'} > Replay </Link></li>
                            <li> <Link to={'./../'} onClick={() => this.backend.missionAbort()} > Afsluiten </Link></li>
                        </>}
                </ul>
            </div>
        </div>)
    }
}
