import React from "react";
import '../styles/_previousButton.scss'
import {Link} from "react-router-dom";

interface PreviousButtonProps {
    previousUrl: string;
}

export class PreviousButton extends React.Component<PreviousButtonProps, any> {

    render() {
        return (
            <div className={'previous-button'}>
                <Link to={this.props.previousUrl}>
                    <div className="button-inner">
                        <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="2.23223" y1="14.2322" x2="14.2322" y2="2.23223" stroke="#174374" strokeWidth="5"/>
                            <line x1="3.76777" y1="12.3982" x2="13.7678" y2="22.3982" stroke="#174374" strokeWidth="5"/>
                            <line x1="5" y1="12.666" x2="33" y2="12.666" stroke="#174374" strokeWidth="5"/>
                        </svg>
                    </div>
                </Link>
            </div>
        )
    }
}
