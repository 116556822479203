import React from 'react';
import {Link} from 'react-router-dom';
import '../styles/_modeSelection.scss';
import nat from '../img/nat.png';
import {PreviousButton} from "../components/PreviousButton";
import blob from '../img/blob-top-right.svg'
import droogButton from '../img/droog-button.svg';

class ModeSelection extends React.Component<any, any> {

    render() {
        return (
            <div className="mode-selection h-full w-full inline-block relative">
                <PreviousButton previousUrl={'./../'}/>
                <div className="illustration">
                    <img src={blob} alt="blob"/>
                </div>
                <h1 className="mt-16 heading w-full absolute tex-center">
                    Kies een categorie
                </h1>
                <div className="container mx-auto mode-options h-full">
                    <div className="flex items-center">
                        <Link to={"droog/"}
                              className="mode-link" >
                            <img src={droogButton} alt=""/>
                            <div className="mode-title table-cell text-center align-middle">Droog</div>
                        </Link>
                    </div>
                    <h2>of</h2>
                    <div className="flex items-center">
                        <Link to={"nat/"}
                              className="mode-link" >
                            <img src={nat} alt="nat"/>
                            <div className="mode-title table-cell text-center align-middle">Nat</div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

}

export default ModeSelection;
