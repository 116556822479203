import {Component} from 'react';

interface QuestionProgressIndicatorProps {

    totalQuestions: number,
    currentQuestion: number,

}

export class QuestionProgressIndicator extends Component<QuestionProgressIndicatorProps, any> {

    render() {
        return (
            <div id="progress-bar" className="flex flex-row h-8">
                {
                    Array.from(Array(this.props.totalQuestions).keys()).map(x => x + 1).map((_, i) => {
                        return (
                            <div key={"progress-block-" + i} className={"flex-grow mx-1 my-1 block rounded-lg " +
                                (this.props.currentQuestion > i
                                ? "bg-rws-dark-blue "
                                : this.props.currentQuestion === i ? "bg-rws-light-blue " : "bg-backdrop ")
                            }/>
                        )
                    })
                }
            </div>
        )
    }

}
