export class FormatUtil {

    static formatDistance(distance: number) {
        return distance.toLocaleString("nl-NL", { style: "decimal", maximumFractionDigits: 0, minimumFractionDigits: 0 });
    }

    static formatTime(time: number) {
        return time.toLocaleString("nl-NL", { style: "decimal", maximumFractionDigits: 1, minimumFractionDigits: 1 });
    }

    static formatScore(score: number) {
        return score.toLocaleString("nl-NL", { style: "decimal", maximumFractionDigits: 0, minimumFractionDigits: 0 });
    }
}
