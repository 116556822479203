import React from "react";
import {VtsSector} from "../data/GeoData";
import vtsSector from "../img/vtsSector.svg";

interface VtsSectorSignProps {
    vtsSector: VtsSector
    vhfChannel: any
}

export class VtsSectorSign extends React.Component<VtsSectorSignProps, any>{
    render() {
        return (
            <div className={'square-gray'}>
                <div className="image">
                    <img src={vtsSector} alt="iceTrajectory"/>
                </div>
                <div className="title">
                    Marifoonkanaal {this.props.vtsSector.vhfChannel}
                </div>
            </div>
        );
    }
}
