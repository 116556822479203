import {retry} from 'rxjs';

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export type Not<T> = [T] extends [never] ? unknown : never
export type Extractable<T, U> = Not<U extends any ? Not<T extends U ? unknown : never> : never>

export function asEnum<E extends Record<keyof E, string | number>, K extends string | number>(
  e: E, k: K & Extractable<E[keyof E], K>
): Extract<E[keyof E], K> {
  // runtime guard, shouldn't need it at compiler time
  if (Object.values(e).indexOf(k) < 0)
    throw new Error("Got: " + k + ", expected one of " + Object.values(e).join(", "));
  return k as any; // assertion
}

export function mapPromise<T, O>(promise: Promise<T>, mapper: (input: T) => O): Promise<O> {

  return new Promise<O>((resolve, reject) =>
    promise
      .then(r => resolve(mapper(r)))
      .catch(e => reject(e))
  );
}

export function mapPromiseArray<T, O>(promise: Promise<T[]>, mapper: (input: T) => O): Promise<O[]> {

  return new Promise<O[]>((resolve, reject) =>
    promise
      .then(r => resolve(r.map(q => mapper(q))))
      .catch(e => reject(e))
  );
}

export async function tryOrElse<T>(tryRun: Promise<T>, orElse?: Promise<T>): Promise<T> {

  try {
    return await tryRun;
  } catch(e) {
    if (orElse) {
      return await orElse;
    }
    throw(e);
  }
}
