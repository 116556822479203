import React from "react";
import {Fairway} from "../data/GeoData";
import fairway from '../img/fairway.svg';
import '../styles/_signs.scss';
interface FairwaySignProps {
    fairway: Fairway
}

export class FairwaySign extends React.Component<FairwaySignProps, any> {

    render() {
        return (
            <div className={'longblue'}>
                <div className="image">
                    <img src={fairway} alt="fairwaysign"/>
                </div>
                <div className="title">
                    {this.props.fairway.name}
                </div>
            </div>
        );
    }
}