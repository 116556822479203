import {useNavigate, useSearchParams} from 'react-router-dom';
import React from "react";
// import {BackendService} from '../data/BackendService';

const RedirectToLogin = ()=> {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        if (searchParams.has("token")) {
        //     BackendService.getInstance().tokenLogin(searchParams.get("token")!!)
        //         .then(session => {
        //             // No need to redirect, we successfully logged in
        //             searchParams.delete("token")
        //             setSearchParams(searchParams, { replace: true })
        //             console.log("Logged in " + session.userUid)
        //         }).catch(e => {
        //             console.log("Token login failed, redirecting to login...");
        //             return navigate("/login", {replace: true});
        //         });
        } else {

            console.log("redirecting to login...");
            return navigate("/login", {replace: true});
        }
    });
    return (<div className="grid place-items-center h-screen"><p className="text-2xl">Je moet nog even inloggen...</p></div>);
}

export default  RedirectToLogin
