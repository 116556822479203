import {Question} from "../Mission";
import {RoadSign} from "./RoadSign";
import {RoadObjectSign} from "./RoadObjectSign";
import {HectometerSign} from "./HectometerSign";
import React from "react";
import {FairwayKilometerMarkSign} from "./FairwayKilometerMarkSign";
import {FairwaySign} from "./FairwaySign";
import {PathOfInterestSign} from "./PathOfInterestSign";
import {VtsSectorSign} from "./VtsSectorSign";
import {PointOfInterestSign} from "./PointOfInterestSign";
import {QuestionType} from '../config/ConfigService';

const QuestionSign = ( question: Question, size: "small" | "large" ) => {

  switch (question.type) {
    case QuestionType.Road: return <RoadSign road={question.data} size={size}/>
    case QuestionType.RoadObject: return <RoadObjectSign roadObject={question.data} size={size} />
    case QuestionType.Hectopunt: return <HectometerSign hectopunt={question.data} size={size} />
    case QuestionType.Fairway: return <FairwaySign fairway={question.data}/>;
    case QuestionType.PointOfInterest: return <PointOfInterestSign poi={question.data}/>;
    case QuestionType.FairwayKilometerMark: return <FairwayKilometerMarkSign kilometerMark={question.data} size={size} />;
    case QuestionType.PathOfInterest: return <PathOfInterestSign pathOfInterest={question.data} />;
    case QuestionType.VtsSector: return <VtsSectorSign   vhfChannel={''} vtsSector={question.data} />;
  }
}

export default QuestionSign;
