import React, {Component, RefObject} from 'react';
import {LngLatBounds, LngLatBoundsLike} from 'mapbox-gl';
import {Layer, Map, MapLayerMouseEvent, MapRef, Marker, NavigationControl, Source} from 'react-map-gl';


import config from './config.json';
import pin_selected from './img/pin.png';
import pin_result from './img/pin_result.png';
import {Feature, Polygon, Position} from 'geojson';
import {GeoUtil} from './util/GeoUtil';
import {delay} from './util/Helpers';
import {MissionMode} from './Mission';

const defaultMaxBounds: LngLatBoundsLike = [[config.map.defaultMaxBounds[0][0], config.map.defaultMaxBounds[0][1]],
    [config.map.defaultMaxBounds[1][0], config.map.defaultMaxBounds[1][1]]]


export type AnswerRetriever = (question: number, p: Position) => Promise<Position>;

interface MissionMapProps {
    mode: MissionMode,
    regionBounds: LngLatBounds,
    answerRetriever: AnswerRetriever,
    onAnswerSelected: (selectedPosition: Position, distance: number ) => void;
    otherRegionOverlayGeometry?: Feature[];
    onLoad?: () => void;
}

interface MissionMapState {
    selectedPosition: Position | null,
    currentQuestion: number,
    answerPosition?: Position,
    answerDistance: number | null,
    answerCircleDistance: number,
    answerCircle: Feature<Polygon>;
    /**
     * Phase the map is currently in.
     * <pre>
     * "answering": Waiting for an answer, use should click on the map to select a position
     * "showingAnswer": Currently showing the answer (during the animation)
     * "shownAnswer": The answer has been shown (animation is done). Waiting for the user to go to the next question
     * "gettingReady": There is a short delay after the user started the next question during which we won't allow input (we assume the map is loading).
     * </pre>
     */
    phase: "answering" | "showingAnswer" | "shownAnswer" | "gettingReady";
    answerPinVisible: boolean,
}

const emptyPolygon: Feature<Polygon> = {
    id: "empty",
    bbox: [0, 0, 0, 0],
    type: 'Feature',
    properties: {},
    geometry: {
        type: 'Polygon',
        bbox: [0, 0, 0, 0],
        coordinates: [],
    }
}

export class MissionMap extends Component<MissionMapProps, MissionMapState> {

    private readonly mapRef: RefObject<MapRef>;

    constructor(props: MissionMapProps) {
        super(props);

        this.state = {
            answerDistance: null,
            phase: 'gettingReady',
            answerCircleDistance: 0,
            answerCircle: emptyPolygon,
            currentQuestion: 0,
            answerPinVisible: false,
            selectedPosition: null,
            answerPosition: [0, 0]
        }

        this.mapRef = React.createRef<MapRef>();
        this.zoomToRegion();
    }

    componentDidMount() {
        this.getReady(true);
    }

    private onMapLoaded() {
        if (this.props.onLoad) {
            this.props.onLoad();
        }
        this.zoomToRegion(true);
    }

    public zoomToRegion(instant = false, regionOverride: LngLatBoundsLike | null = null) {
        this.mapRef.current?.fitBounds(regionOverride ?? this.props.regionBounds, {
            animate: !instant,
            duration: 400,
            padding: 50,
        });
    }

    getReady(isFirst: boolean = false) {
        setTimeout(() => {
            this.setState({...this.state,
                phase: 'answering',
            });
        }, isFirst ? config.mission.questionStartDelay.first : config.mission.questionStartDelay.default);
    }

    public nextQuestion() {
        this.setState({...this.state,
            selectedPosition: null,
            currentQuestion: this.state.currentQuestion + 1,
            answerPosition: undefined,
            answerCircle: emptyPolygon,
            answerPinVisible: false,
            phase: 'gettingReady',
            answerDistance: null,
        }, () => {
            this.zoomToRegion();
            this.getReady();
        });
    }

    setAnswerPosition(answerPosition: Position) {
        this.setState({...this.state, answerPosition});
    }

    private onMapClicked(event: MapLayerMouseEvent) {

        // Only do something if no location has been selected yet
        if (this.state.phase !== 'answering') return;

        const selectedPosition = [event.lngLat.lng, event.lngLat.lat];
        this.setState({...this.state, selectedPosition},
            () => {
            this.props.answerRetriever(this.state.currentQuestion, selectedPosition)
                .then(correctAnswer => {
                    const distance = GeoUtil.calculateDistance(selectedPosition, correctAnswer);
                    this.setState({
                        ...this.state,
                        answerPosition: correctAnswer,
                        selectedPosition: selectedPosition,
                        answerDistance: distance,
                    }, () => this.props.onAnswerSelected(selectedPosition, distance)
                    );
                });
        });
    }

    public async showAnswerSequence(): Promise<any> {

        console.log(`Showing answer to question ${this.state.currentQuestion} (selected ${this.state.selectedPosition})`);
        const regionBounds = this.props.regionBounds;
        if (this.state.selectedPosition) {

            // Update the state to inform the game that the animation is running
            this.setState({
                ...this.state,
                phase: 'showingAnswer'
            });

            const needsExtendedBounds = this.mapRef?.current?.getBounds() !== this.props.regionBounds ||
              (this.state.selectedPosition && !GeoUtil.bboxContains(regionBounds, this.state.selectedPosition!!));

            if (needsExtendedBounds) {
                let extendedBounds = GeoUtil.bboxContains(regionBounds, this.state.selectedPosition!!)
                  ? regionBounds
                  : new LngLatBounds({
                      lng: Math.min(this.state.selectedPosition!![0], regionBounds.getSouthWest().lng),
                      lat: Math.min(this.state.selectedPosition!![1], regionBounds.getSouthWest().lat)
                  }, {
                      lng: Math.max(this.state.selectedPosition!![0], regionBounds.getNorthEast().lng),
                      lat: Math.max(this.state.selectedPosition!![1], regionBounds.getNorthEast().lat)
                  });

                this.zoomToRegion(false, extendedBounds);
            }

            await delay(config.mission.answerAnimation.delayBeforeAnswerPinDrops);

            // If the user went to the next question during the animation, we stop the animation
            if (!this.state.selectedPosition || this.state.phase !== 'showingAnswer') {
                return;
            }

            // Drop the answer pin
            this.setState({
                ...this.state,
                answerPinVisible: true,
            });

            // Wait a bit before starting to grow the circle
            await delay(config.mission.answerAnimation.delayBeforeCircle);

            const answerDistance = this.state.answerDistance!!;

            const animationLength = config.mission.answerAnimation.circleAnimationLength;
            const startTime = performance.now();
            let animationTime = 0;
            while (animationTime < animationLength) {
                // If the user went to the next question during the animation, we stop the animation
                if (!this.state.selectedPosition || this.state.phase !== 'showingAnswer') {
                    return;
                }

                const alpha = animationTime / animationLength;
                const answerCircleDistance = alpha * answerDistance;
                this.setState({
                    ...this.state,
                    answerCircleDistance,
                    answerCircle: GeoUtil.buildCircle(this.state.selectedPosition!!, answerCircleDistance)
                });
                animationTime = performance.now() - startTime;
                await delay(10);
            }
            // Drop the answer pin
            this.setState({...this.state,
                answerDistance,
                answerCircle: GeoUtil.buildCircle(this.state.selectedPosition!!, answerDistance),
                answerPinVisible: true,
                phase: 'shownAnswer',
            });
        } else {
            this.zoomToRegion(false);
            this.setState({
                answerDistance: null,
                answerCircle: emptyPolygon,
                answerPinVisible: true,
                phase: 'shownAnswer',
            })
        }


    }

    render() {
        return (
            <Map ref={this.mapRef}
                 reuseMaps={true}
                 mapStyle={ this.props.mode === MissionMode.Droog ? config.mapbox.styleDroog : config.mapbox.styleNat }
                 mapboxAccessToken={config.mapbox.accessToken}
                 initialViewState={{
                     bounds: this.props.regionBounds,
                 }}
                 maxBounds={defaultMaxBounds}
                 maxPitch={0}
                 touchPitch={false}
                 dragRotate={false}
                 touchZoomRotate={false}
                 style={{
                     height: "100%",
                     width: "100%",
                     position: "relative",
                 }}
                 logoPosition="top-right"
                 cursor={'crosshair'}

                // If the data is loaded before the map, the "zoomToRegion" call is ignored. So we set it manually here
                 onLoad= {(_) => this.onMapLoaded() }
                 onClick = {(e) => this.onMapClicked(e)}
            >
                <NavigationControl position={"bottom-right"} showCompass={false} />
                { this.state.selectedPosition
                    ? (<Marker longitude={this.state.selectedPosition[0]} latitude={this.state.selectedPosition[1]} anchor="bottom" clickTolerance={0}>
                        <img src={pin_selected} alt="pin" width="48px" />
                    </Marker>)
                    : undefined
                }
                {
                    this.props.otherRegionOverlayGeometry
                        ?
                        (
                            <Source type={'geojson'} id={'other-areas-overlay'}
                                    data={{
                                        type: 'FeatureCollection',
                                        features: this.props.otherRegionOverlayGeometry!!
                                    }}>
                                <Layer
                                    type="fill"
                                    source="other-areas-overlay"
                                    paint={{
                                        "fill-color": "rgb(23, 67, 116)",
                                        "fill-opacity": 0.5,
                                    }}
                                    id="other-areas-overlay-fill"/>
                            </Source>
                        ) : undefined
                }
                {
                    this.state.phase === "showingAnswer" || this.state.phase === "shownAnswer" ?
                        (
                            <Source type="geojson" id="answer-circle" data={this.state.answerCircle!!}>
                                <Layer
                                    type="fill"
                                    source="answer-circle"
                                    paint={{
                                        "fill-color": "rgba(2, 111, 212, 0.2)",
                                        "fill-opacity": 1,
                                    }}
                                    id="answer-circle-fill"   />
                                <Layer
                                    type="line"
                                    source="answer-circle"
                                    paint={{
                                        "line-width": 2,
                                        "line-color": "#026FD4"
                                    }}
                                    id="answer-circle-outline"   />
                            </Source>
                        )
                        : undefined
                }
                { this.state.answerPinVisible
                    ? (<Marker longitude={this.state.answerPosition!![0]} latitude={this.state.answerPosition!![1]} anchor="bottom">
                        <img src={pin_result} alt="pin" width="48px" />
                    </Marker>)
                    : undefined
                }
            </Map>
        )
    }
}
