import {Route, Routes} from 'react-router-dom';
import {Start} from './pages/Start';
import ModeSelection from './pages/ModeSelection';
import RegionSelectionOptions from './RegionSelectionOptions';
import DifficultyOptions from './pages/DifficultyOptions';
import MissionPage from './pages/MissionPage';
import {TipsPage} from './pages/TipsPage';
import {SupportPage} from './pages/SupportPage';
import {InfoPage} from './pages/InfoPage';
import {NameGenerator} from './pages/NameGenerator';
import {ScorePage} from './pages/ScorePage';
import {LeaderBoard} from './pages/LeaderBoard';
import RedirectToLogin from './components/RedirectToLogin';
import React, {useState} from 'react';
import {BackendService, PlayerData} from './data/BackendService';
import RedirectToRestrictedMission from './RedirectToRestrictedMission';
import LoginPage from './pages/LoginPage';
import Loading from './components/Loading';
import {MissionTester} from './pages/MissionTester';


type AppRouterState = {
  signedIn: boolean,
  hasSession: boolean,
  missionRestriction: string | null,
  loading: boolean,
  loggedInPlayer: PlayerData | null,
  startPath: string
}

function AppRouter() {

  const backend = BackendService.getInstance();

  const [state, setState] = useState<AppRouterState>({
    loading: true,
    signedIn: backend.isSignedIn(),
    hasSession: backend.hasSession(),
    loggedInPlayer: backend.loggedInPlayer.getValue(),
    startPath: window.location.href,
    missionRestriction: null,
  });

  backend.userSignedIn.subscribe(s => {
    if (s !== state.signedIn) setState({...state, signedIn: s})
  });
  backend.userSessionActive.subscribe(s => {
    if (s !== state.hasSession) setState({...state, hasSession: s})
  });
  backend.loggedInPlayer.subscribe(s => {
    if (s !== state.loggedInPlayer) setState({...state, loggedInPlayer: s})
  });
  backend.missionRestriction.subscribe(mr => { if (mr !== state.missionRestriction) setState({...state, missionRestriction: mr}) });

  if (state.signedIn) {
    if (!state.hasSession) {
      backend.startUserSession().then();
    } else if (!state.loggedInPlayer) {
      backend.getPlayerMe().then()
    }
  }

  return (
    <Routes>
      {
        state.signedIn ?
          state.hasSession && state.loggedInPlayer ?
            state.missionRestriction ?
              state.loggedInPlayer.nickname ?
                <>
                  <Route path={"/mode/:mode/:region/difficulty/:level"} element={(<MissionPage/>)}/>
                  <Route path={'score'} element={(<ScorePage/>)}/>
                  <Route path={'leaderboard'} element={(<LeaderBoard/>)}/>
                  <Route path={"/*"} element={<RedirectToRestrictedMission />} />
                </>
                : (<Route path={"/*"} element={(<NameGenerator/>)}/>)
              : (<>
                <Route path="/" element={<Start/>}/>
                {state.loggedInPlayer.nickname
                  ? (<>
                    <Route path="/mode" element={<ModeSelection/>}/>
                    <Route path="/mode/:mode" element={(<RegionSelectionOptions/>)}/>
                    <Route path="/mode/:mode/:region/difficulty" element={(<DifficultyOptions/>)}/>
                    <Route path={"/mode/:mode/:region/difficulty/:level"} element={(<MissionPage/>)}/>
                    <Route path={'/tips'}
                           element={(<TipsPage showBackButtonExplicitly={true} showMission={false} tipsPopup={true}/>)}/>
                    <Route path={'/support'} element={(<SupportPage/>)}/>
                    <Route path={'info'} element={(<InfoPage/>)}/>
                    <Route path={'name-generator'} element={(<NameGenerator/>)}/>
                    <Route path={'score'} element={(<ScorePage/>)}/>
                    <Route path={'leaderboard'} element={(<LeaderBoard/>)}/>
                    <Route path={'test/missions'} element={(<MissionTester/>)}/>
                  </>)
                  : <Route path={"/*"} element={(<NameGenerator/>)}/>
                }
              </>)
            : <Route path={"/*"} element={<Loading text={"Spel voorbereiden..."}/>} />
          : (<>
            <Route path={"/login"} element={<LoginPage />} />
            <Route path={"/*"} element={(<RedirectToLogin/>)}/>
            </>
          )
      }
    </Routes>
  );


}

export default AppRouter;
