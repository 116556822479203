import {NavigateFunction, useNavigate} from 'react-router-dom';
import {ComponentType} from 'react';
import {Diff} from 'utility-types';

interface WithNavigateProps {
    navigate: NavigateFunction;
}

export default function withNavigate<T extends WithNavigateProps>(
    WrappedComponent: ComponentType<T>
) {
    // note below that Diff removes the props from T that exist in WithWoofStringProps
    // this leaves us with the props of the originally passed in component (without the injected props)
    return (props: Diff<T, WithNavigateProps>) => {
        const navigate = useNavigate();
        return <WrappedComponent {...(props as T)} navigate={navigate} />
    }
}
