import React from "react";
import {PointOfInterest} from "../data/GeoData";
import berth from '../img/berth.svg';
import bunker from '../img/bunker.svg';
import carDropOff from '../img/cardDropOff.svg'
import fairyWayConnection from '../img/fairway-connection.svg';
import radio from '../img/radiocallingpoint.svg';
import terminal from '../img/terminal.svg';
import exceptnav from '../img/except-nav.svg';
import trailer from '../img/trailerSlipAway.svg';
import bridge from '../img/bridge.svg';
import ferry from '../img/ferry.svg';
import lock from '../img/lockSign.svg';
import harbour from '../img/harbour.svg';
interface PointOfInterestSignProps {
    poi: PointOfInterest
}

export class PointOfInterestSign extends React.Component<PointOfInterestSignProps, any> {

    renderSignBasedOnObjectKind() {
        switch (this.props.poi.objectKind) {
            case 'berth':
                return (
                    <div className={'square-blue'}>
                        <div className="image">
                            <img src={berth} alt="berth"/>
                        </div>
                        <div className="title">
                        {this.props.poi.name }
                        </div>
                    </div>
                )
            case 'bridge':
                return (
                    <div className={'longgray'}>
                        <div className="image">
                            <img src={bridge} alt="bridge"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name}
                        </div>
                    </div>
                )
            case 'bunkerstation':
                return (
                    <div className={'square-blue'}>
                        <div className="image">
                            <img src={bunker} alt="bunker"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name }
                        </div>
                    </div>
                )
            case 'cardropoff':
                return (
                    <div className={'square-blue'}>
                        <div className="image">
                            <img src={carDropOff} alt="carDropOff"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name }
                        </div>
                    </div>
                )
            case 'fairwayconnection':
                return (
                    <div className={'longblue'}>
                        <div className="image">
                            <img src={fairyWayConnection} alt="fairway connection sign"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name}
                        </div>
                    </div>
                )
            case 'ferrylandingpoint':
                return (
                    <div className={'longgray'}>
                        <div className="image">
                            <img src={ferry} alt="ferry"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name}
                        </div>
                    </div>
                )
            case 'lock': case 'chamber':
                return (<div className={'longgray'}>
                    <div className="image">
                        <img src={lock} alt="lock"/>
                    </div>
                    <div className="title">
                        {this.props.poi.name}
                    </div>
                </div>)
            case 'radiocallinpoint':
                return (
                    <div className={'square-gray'}>
                        <div className="image">
                            <img src={radio} alt="radiocallingpoint"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name}
                        </div>
                    </div>
                )
            case 'terminal':
                return (
                    <div className={'square-blue'}>
                        <div className="image">
                            <img src={terminal} alt="terminal"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name }
                        </div>
                    </div>
                )
            case 'trailerslipway':
                return (
                    <div className={'square-blue'}>
                        <div className="image">
                            <img src={trailer} alt="trailer slip away"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name }
                        </div>
                    </div>
                )
            case 'exceptionalnavigationalstructure':
                return (
                    <div className={'longgray'}>
                        <div className="image">
                            <img src={exceptnav} alt="Kunstwerkcomplex"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name }
                        </div>
                    </div>
                )
            case 'vinharbour': case 'nwbharbour':
                return (
                    <div className={'square-gray'}>
                        <div className="image">
                            <img src={harbour} alt="Haven"/>
                        </div>
                        <div className="title">
                            {this.props.poi.name }
                        </div>
                    </div>
                )
        }

    }

    render() {
        return (
            <div>
                {this.renderSignBasedOnObjectKind()}
            </div>
        );
    }
}
