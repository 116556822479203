import React from 'react'
import {FormatUtil} from './util/FormatUtil';
import {Question} from './Mission';
import './styles/_missionResult.scss';
import config from './config.json';
import QuestionSign from "./question-types/Question";
import {BackendService} from "./data/BackendService";
import {Link} from "react-router-dom";
import {TipsPage} from "./pages/TipsPage";
import {PreviousButton} from "./components/PreviousButton";
import grayStar from "./img/gray-star.svg";
import goldStar from "./img/gold-star.svg";
import tripleLine from './img/triple-line.svg';
import {MathUtil} from "./util/MathUtil";

interface MissionResultProps {
    questions: Question[],
    missionMode: string,
    missionLevel: number,
    missionRegion: string,
    answerDistances: number[],
    answerTimes: number[],
    answerScores: number[],
    onRestartClicked: () => void,
}

export interface MissionResultState {
    tipsPopup: boolean
}

export class MissionResult extends React.Component<MissionResultProps, MissionResultState> {
    private backend = BackendService.getInstance();

    slicedUrl = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'))
    private missionHighscore = 0;

    constructor(props: MissionResultProps) {
        super(props);
        this.state = {
            tipsPopup: false
        }
    }

    determinePopupState(popup: boolean) {
        this.setState({
            tipsPopup: popup
        })
    }

    closePopup() {
        this.setState({
            tipsPopup: false
        });
    }

    componentDidMount() {
        const currentMissionScore = MathUtil.sum(this.props.answerScores);
        this.backend.getPlayerMe()
          .then(p => {
              const missionUid = this.backend.determineMissionUid(this.props.missionMode, this.props.missionRegion, this.props.missionLevel);
              const missionProgress = p.progress.missionProgress.find(m => m.missionUid == missionUid);
              if (missionProgress && missionProgress.score > currentMissionScore) {
                  this.missionHighscore = missionProgress.score;
              } else {
                  this.missionHighscore = currentMissionScore;
              }
          })
    }


    //render amount of stars based on score & config
    renderTotalScoreStars(score: number): React.ReactNode {
        let star = 0;
        if (score) {
            if (Number(score) < config.mission.stars.oneStar) {
                star = 0;
            }
            if (Number(score) >= config.mission.stars.oneStar) {
                star = 1;
            }
            if (Number(score) >= config.mission.stars.twoStars) {
                star = 2;
            }
            if (Number(score) >= config.mission.stars.threeStars) {
                star = 3;
            }
        }
        switch (star) {
            case 0:

                return (
                    <div className={'stars-inner'}>
                        <img src={grayStar} alt="gray star"/><img src={grayStar} alt="gray star"/><img src={grayStar}
                                                                                                       alt="gray star"/>
                    </div>
                );
            case 1:

                return (
                    <div className={'stars-inner'}>

                        <img src={goldStar} alt="gold star"/><img src={grayStar} alt="gray star"/><img src={grayStar}
                                                                                                       alt="gray star"/>

                    </div>
                )
            case 2:
                return (
                    <div className={'stars-inner'}>
                        <img src={goldStar} alt="gold star"/><img src={goldStar} alt="gold star"/><img src={grayStar}
                                                                                                       alt="gray star"/>
                    </div>
                )
            case 3:
                return (
                    <div className={'stars-inner'}>
                        <img src={goldStar} alt="gold star"/><img src={goldStar} alt="gold star"/><img src={goldStar}
                                                                                                       alt="gold star"/>
                    </div>
                )
        }
    }

    //set progress bar color range based on score percentage
    setColorBasedOnWidth(percentage: number): string {
        if (percentage > 0 && percentage < 25) {

            return '#3a8d24';
        } else if (percentage > 24 && percentage < 50) {
            return '#7ba01c';
        } else if (percentage > 49 && percentage < 75) {
            return '#997815';
        } else if (percentage > 74 && percentage < 90) {
            return '#a41818';
        } else if (percentage > 89 && percentage <= 100) {
            return '#a41818'
        }
        return '#a41818'
    }

    //determine score percentage by distance
    renderProgressDistancePercentage(distance: number): number {
        let maxDistance = config.mission.scoring.distance.max
        let percentage;
        let difference = distance - maxDistance
        percentage = (100 * Number(difference)) / maxDistance
        if (percentage > 100) {
            percentage = 100;
        }
        return percentage + 100
    }

    //determine score percentage by time
    renderProgressTimePercentage(time: number) {
        let maxTime = config.mission.scoring.time.max;
        let percentage;
        let difference = time - maxTime;
        percentage = (100 * Number(difference)) / maxTime
        if (percentage > 100) {
            percentage = 100
        }
        return percentage + 100
    }

    //renders message based on amount of stars
    renderMessage(): React.ReactNode {
        const totalScore = Number(this.props.answerScores.reduce((a, b) => a + b, 0));
        if (totalScore < config.mission.stars.oneStar) {
            return (
                <span>Oei, je hebt niet genoeg punten behaald om door te mogen naar de volgende ronde. Probeer het nog een keer!</span>
            )
        }
        if (totalScore >= config.mission.stars.oneStar && totalScore < config.mission.stars.twoStars) {
            return (
                <span>Goed gedaan! Je hebt genoeg punten behaald om door te mogen naar een hoger niveau. Maar kun je hier ook 2 sterren halen?<br/> {sessionStorage?.getItem('returnUrl') ? 'Klik op afsluiten en speel de volgende missie.':''}</span>
            )
        }
        if (totalScore >= config.mission.stars.twoStars && totalScore < config.mission.stars.threeStars) {
            return (
                <span>Super!! Je hebt al 2 sterren behaald. Als je nog iets sneller en nauwkeuriger bent, kun je misschien 3 sterren halen! <br/> {sessionStorage?.getItem('returnUrl') ? 'Klik op afsluiten en speel de volgende missie.':''}</span>
            )
        }
        if (totalScore >= config.mission.stars.threeStars) {
            return (
                <span>Wauw!!! Je hebt drie sterren behaald. Supergoed gedaan. Heb je hiermee ook de hoogste score of kun je nog beter worden dan je collega’s? <br/> {sessionStorage?.getItem('returnUrl') ? 'Klik op afsluiten en speel de volgende missie.':''}</span>
            )
        }
    }

    render() {
        return (
            <div className={'mission-result'}>
                <div className="tips-popup">
                    <div className={this.state.tipsPopup ? 'tools' : 'dont-show-tools'}>
                        <div className="closePopup" onClick={() => this.closePopup()}>
                            <PreviousButton previousUrl={'./'}/>
                        </div>
                    </div>
                    <TipsPage showMission={true} tipsPopup={this.state.tipsPopup}/>
                </div>
                <div id="mission-results-header " className={"my-4 mission-results-header"}>
                    <h1 className=" mt-5 text-3xl text-center font-bold">{this.renderMessage()}</h1>
                </div>
                <div id="total-score" className="text-center  mt-5 w-100 mb-3">
                    <img src={tripleLine} alt="triple line"/>
                    <div className="score-cards">
                                <span
                                    className={" small-score-card text-2xl font-bold"}>Missie doel<br/>
                                    <div className="stars mt-1">
                                        <div className={'stars-inner'}>
                                            <img src={goldStar} alt="*"/><img src={grayStar} alt="-"/><img src={grayStar} alt="-"/>
                                        </div>
                                    </div>
                            </span>
                        <span
                            className={" big-score-card text-2xl font-bold"}>Totale score<br/> {FormatUtil.formatScore(this.props.answerScores.reduce((a, b) => a + b, 0))}
                            <div className="stars">
                                    {this.renderTotalScoreStars(MathUtil.sum(this.props.answerScores))}
                                        </div>
                            </span>
                        <span
                            className={" small-score-card text-2xl font-bold"}>Highscore<br/> {FormatUtil.formatScore(this.missionHighscore)}
                            <div className="stars">
                                    {/*{this.renderStars(this.props.missionLevel)}*/}
                                        </div>
                            </span>
                    </div>
                    <span className={'triple-line'}><img src={tripleLine} alt="tripleline"/></span>


                </div>
                <div className={"text-center flex justify-center max-w-screen-lg mx-auto mt-10 pb-10"}>
                    <button onClick={() => this.determinePopupState(true)} type={"button"}
                            className="mr-4 m-result-replay-button bg-rws-dark-blue text-backdrop py-2 px-8 rounded text-xl">
                        <span className="title">TIPS</span>
                    </button>
                    <button type={"button"}
                            className="mr-4 m-result-replay-button bg-rws-dark-blue text-backdrop py-2 px-8 rounded text-xl"
                            onClick={() => this.props.onRestartClicked()}><span className="title">OPNIEUW SPELEN</span>
                    </button>
                    {
                        this.backend.missionRestriction.value
                      ?
                          <>
                              <Link to={'/score'}>
                                  <div  className="mr-4 m-result-replay-button bg-rws-dark-blue text-backdrop py-2 px-8 rounded text-xl">
                                      <span className="title">SCORES</span>
                                  </div>
                              </Link>
                              <a href={this.backend.returnUrl.value ?? this.slicedUrl}>
                                  <button type={"button"}
                                          className="mr-4 m-result-replay-button bg-rws-dark-blue text-backdrop py-2 px-8 rounded text-xl">
                                      <span className="title">AFSLUITEN</span>
                                  </button>
                              </a>
                          </>
                          :
                          <Link
                            to={ '../mode/' + this.props.missionMode.toLowerCase() + '/' + this.props.missionRegion + '/difficulty' } replace={true}>
                              <button type={"button"}
                                      className="mr-4 m-result-replay-button bg-rws-dark-blue text-backdrop py-2 px-8 rounded text-xl">
                                  <span className="title">DOORGAAN</span>
                              </button>
                          </Link>

                    }
                </div>
                <div className=" w-full h-full z-10">
                    <div className="main-content text-center container  mx-auto">

                        <div id="mission-results" className={"grid grid-rows-5 grid-flow-col mb-2"}>
                            {this.props.questions.map((q, i) => {
                                return (
                                    <div key={"answer" + i} className=" result-item h-28 flex items-center ">
                                        <div className={"inline-block w-20"}><span className={"text-2xl"}><b>{i + 1}</b></span>
                                        </div>
                                        <div className={'questionSign'}>
                                            {QuestionSign(q, "small")}
                                        </div>
                                        <div className={"inline-block px-1 mx-1 w-full"}>
                                            <table className={"table-auto w-full"}>
                                                <tbody>
                                                <tr>
                                                    <td className={"text-left w-16 score-text"}>Score
                                                        = <b> {FormatUtil.formatScore(this.props.answerScores[i])} punten</b>
                                                    </td>
                                                    <td className={"text-right w-16 score-text"}><span
                                                        className={"font-bold score-text"}></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={"text-left w-16 score-text"}>Afstand
                                                        = {this.props.answerDistances[i] < 0
                                                            ? '-'
                                                            :
                                                            <b>{FormatUtil.formatDistance(this.props.answerDistances[i])} meter</b>
                                                        }</td>
                                                </tr>
                                                <tr>
                                                    <div className="progress-bar-result">
                                                        <div className="progress" style={{
                                                            width: this.renderProgressDistancePercentage(this.props.answerDistances[i]) + "%",
                                                            backgroundColor: this.setColorBasedOnWidth(this.renderProgressDistancePercentage(this.props.answerDistances[i]))
                                                        }}></div>
                                                        <div className="baseline"></div>
                                                    </div>
                                                </tr>
                                                <tr>
                                                    <td className={"text-left score-text"}>Tijd
                                                        = <b>{FormatUtil.formatTime(this.props.answerTimes[i])} seconden</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <div className="progress-bar-result">
                                                        <div className="progress" style={{
                                                            width: this.renderProgressTimePercentage(this.props.answerTimes[i]) + "%",
                                                            backgroundColor: this.setColorBasedOnWidth(this.renderProgressTimePercentage(this.props.answerTimes[i]))
                                                        }}></div>
                                                        <div className="baseline"></div>
                                                    </div>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </div>
        )
    }

}
