import {
  DistanceResult,
  Fairway,
  FairwayKilometerMark,
  Hectopunt,
  PathOfInterest,
  PointOfInterest,
  RegionMetaResponse,
  Road,
  RoadObject,
  VtsSector
} from './GeoData';
import axios from "axios";
import {Position} from 'geojson';
import {QuestionConfig, QuestionType} from '../config/ConfigService';
import {mapPromise, mapPromiseArray, tryOrElse} from '../util/Helpers';
import {Question, QuestionDataType} from '../Mission';

export class GeoDataService {

  private static instance: GeoDataService;
  private droogRegionCache?: RegionMetaResponse;
  private natRegionCache?: RegionMetaResponse;

  public static getInstance(): GeoDataService {
    if (!GeoDataService.instance) {
      GeoDataService.instance = new GeoDataService();
    }

    return GeoDataService.instance;
  }

  async getDroogRegions(): Promise<RegionMetaResponse> {

    if (this.droogRegionCache) return this.droogRegionCache;
    let response = await axios.get<RegionMetaResponse>(process.env.REACT_APP_GEOAPI_URL + "/droog/regions")
    if (response.status !== 200) {
      throw Error(`Failed to retrieve the regions, status ${response.status}: ${response.statusText}, message: '${response.data}'`);
    }
    this.droogRegionCache = response.data;
    return response.data;
  }

  async getNatRegions(): Promise<RegionMetaResponse> {

    if (this.natRegionCache) return this.natRegionCache;

    let response = await axios.get<RegionMetaResponse>(process.env.REACT_APP_GEOAPI_URL + "/nat/regions")
    if (response.status !== 200) {
      throw Error(`Failed to retrieve the regions, status ${response.status}: ${response.statusText}, message: '${response.data}'`);
    }
    this.natRegionCache = response.data;
    return response.data;
  }



  async getDroogHectopuntenRandom(regionCode: string, amount: number, category?: string): Promise<Hectopunt[]> {

    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/droog/hecto/random", {
      params: {
        amount,
        region: regionCode,
        category
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to retrieve hectopunten, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;

  }


  async getDroogRoadsRandom(regionCode: string, amount: number, category?: string): Promise<Road[]> {

    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/droog/road/random", {
      params: {
        amount,
        region: regionCode,
        category,
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to retrieve roads, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;

  }

  async getDroogRoadDistance(wegnummer: string, p: Position): Promise<DistanceResult> {
    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/droog/road/" + wegnummer + "/distance", {
      params: {
        lon: p[0],
        lat: p[1],
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to find distance for road ${wegnummer}, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;

  }

  async getDroogRoadObjects(regionCode:string, amount: number): Promise<RoadObject[]>{
    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + '/droog/road_object/random?', {
      params: {
        amount,
        region: regionCode,
      }
    });
    if (response.status !== 200){
      throw Error(`Failed to retrieve road objects, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;
  }


  async getNatFairwaysRandom(regionCode: string, amount: number): Promise<Fairway[]> {

    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/nat/fairway/random", {
      params: {
        amount,
        region: regionCode,
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to retrieve fairways, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;
  }

  async getNatFairwayDistance(fairwayId: number, p: Position): Promise<DistanceResult> {
    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + `/nat/fairway/${fairwayId}/distance`, {
      params: {
        lon: p[0],
        lat: p[1],
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to find distance for fairway with ID ${fairwayId}, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;

  }

  async getNatPointsOfInterestRandom(regionCode: string, amount?: number, amountPerObjectKind?: { [key: string]: number }): Promise<PointOfInterest[]> {

    let params: { [key: string]: (number | string) } = {
      region: regionCode,
    };
    if (amount) {
      params["amount"] = amount;
    }
    if (amountPerObjectKind) {
      Object.keys(amountPerObjectKind).forEach(k => {
        params[k] = amountPerObjectKind[k]
      });
    }

    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/nat/point-of-interest/random", { params });
    if (response.status !== 200) {
      throw Error(`Failed to retrieve points of interest, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;
  }

  async getNatPathsOfInterestRandom(regionCode: string, amount?: number, amountPerObjectKind?: { [key: string]: number }): Promise<PathOfInterest[]> {

    let params: { [key: string]: (number | string) } = {
      region: regionCode,
    };
    if (amount) {
      params["amount"] = amount;
    }
    if (amountPerObjectKind) {
      Object.keys(amountPerObjectKind).forEach(k => {
        params[k] = amountPerObjectKind[k]
      });
    }

    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/nat/path-of-interest/random", { params });
    if (response.status !== 200) {
      throw Error(`Failed to retrieve paths of interest, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;
  }

  async getNatPathOfInterestDistance(objectKind: string, id: number, p: Position): Promise<DistanceResult> {
    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + `/nat/path-of-interest/${objectKind}/${id}/distance`, {
      params: {
        lon: p[0],
        lat: p[1],
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to find distance for path of interest of kind ${objectKind} with ID ${id}, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;

  }

  async getNatFairwayKilometerMarksRandom(regionCode: string, amount: number): Promise<FairwayKilometerMark[]> {

    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/nat/kilometermark/random", {
      params: {
        amount,
        region: regionCode,
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to retrieve fairway kilometer marks, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;
  }

  async getVtsSectorsRandom(regionCode: string, amount: number): Promise<VtsSector[]> {

    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + "/nat/vts-sector/random", {
      params: {
        amount,
        region: regionCode,
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to retrieve VTS sectors, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;
  }

  async getNatVtsSectorDistance(id: number, p: Position): Promise<DistanceResult> {
    let response = await axios.get(process.env.REACT_APP_GEOAPI_URL + `/nat/vts-sector/${id}/distance`, {
      params: {
        lon: p[0],
        lat: p[1],
      }
    });
    if (response.status !== 200) {
      throw Error(`Failed to find distance to VTS Sector with ID ${id}, status ${response.status}: ${response.statusText}, message: "${response.data}"`);
    }
    return response.data;

  }


  public buildQuestionPromise(region: string, questionConfigs: QuestionConfig[]): Promise<Question[]> {

    let questionTypes = questionConfigs.map(q => q.questionType)
        // Cool single line unique filter, to find all question types:
        .filter((item, i, self) => self.indexOf(item) === i);

    let promises: Promise<Question[]>[] = [];
    const fallback = questionConfigs.find(q => q.isFallback);

    for (let questionType of questionTypes) {
      const configsOfType = questionConfigs.filter(qc => qc.questionType === questionType && !qc.isFallback);
      promises.push(...this.buildPromisesForConfigs(configsOfType, questionType, region, fallback));
    }

    return mapPromise(Promise.all(promises), p => p.flatMap(q => q));
  }

  private buildPromisesForConfigs(configsOfType: QuestionConfig[], questionType: QuestionType, region: string,
                                  fallback?: QuestionConfig)
      : Promise<Question[]>[] {

    const mismatchedConfig = configsOfType.find(c => c.questionType !== questionType);
    if (mismatchedConfig) {
      throw Error(`Can't build promise of question type ${questionType} for question config with question type ${mismatchedConfig.questionType}`);
    }

    let promises: Promise<Question[]>[] = [];

    const totalAmount = configsOfType.map(a => a.amount).reduce((a, b) => a + b);
    switch (questionType) {
      case QuestionType.Road:
        for (const configOfType of configsOfType) {
          promises.push(tryOrElse(mapPromiseArray(this.getDroogRoadsRandom(region, configOfType.amount, configOfType.objectKind), data => ({
            type: QuestionType.Road,
            data
          })),
              this.buildFallbackPromise(fallback, region, configOfType.amount)));
        }
        break;
      case QuestionType.RoadObject:
        promises.push(tryOrElse(mapPromiseArray(this.getDroogRoadObjects(region, totalAmount), data => ({
          type: QuestionType.RoadObject,
          data
        })),
            this.buildFallbackPromise(fallback, region, totalAmount)));
        break;
      case QuestionType.Hectopunt:
        for (const configOfType of configsOfType) {
          promises.push(tryOrElse(mapPromiseArray(this.getDroogHectopuntenRandom(region, configOfType.amount, configOfType.objectKind), data => ({
            type: QuestionType.Hectopunt,
            data
          })),
              this.buildFallbackPromise(fallback, region, configOfType.amount)));
        }
        break;
      case QuestionType.Fairway:
        promises.push(tryOrElse(mapPromiseArray(this.getNatFairwaysRandom(region, totalAmount), data => ({
          type: QuestionType.Fairway,
          data
        })),
            this.buildFallbackPromise(fallback, region, totalAmount)));
        break;
      case QuestionType.PointOfInterest:
        // Point of interests support different object kinds:
        promises.push(...this.buildRequestsForObjectKinds(region, configsOfType,
            (r, a, apo) => this.getNatPointsOfInterestRandom(r, a, apo),
            data => ({type: QuestionType.PointOfInterest, data} as Question), fallback));
        break;
      case QuestionType.PathOfInterest:
        // Point of interests support different object kinds:
        promises.push(...this.buildRequestsForObjectKinds(region, configsOfType,
            (r, a, apo) => this.getNatPathsOfInterestRandom(r, a, apo),
                data => ({type: QuestionType.PathOfInterest, data} as Question), fallback));
        break;
      case QuestionType.FairwayKilometerMark:
        promises.push(tryOrElse(mapPromiseArray(this.getNatFairwayKilometerMarksRandom(region, totalAmount), data => ({
          type: QuestionType.FairwayKilometerMark,
          data
        })), this.buildFallbackPromise(fallback, region, totalAmount)));
        break;
      case QuestionType.VtsSector:
        promises.push(tryOrElse(mapPromiseArray(this.getVtsSectorsRandom(region, totalAmount), data => ({
          type: QuestionType.VtsSector,
          data
        })), this.buildFallbackPromise(fallback, region, totalAmount)));
    }

    return promises;
  }

  private buildFallbackPromise(fallback: QuestionConfig|undefined, region: string, amount: number): Promise<Question[]>|undefined {

    if (!fallback) return undefined;

    const fallbackPromises = this.buildPromisesForConfigs([{...fallback, amount}], fallback.questionType, region);
    return fallbackPromises[0];
  }

  private buildRequestsForObjectKinds<T extends QuestionDataType>(region: string,
                                                                  configsOfType: QuestionConfig[],
                                                                  requestCreationFunc: (regionCode: string, amount?: number, amountPerObjectKind?: { [key: string]: number }) => Promise<T[]>,
                                                                  questionMapper: (t: T) => Question,
                                                                  fallback: QuestionConfig|undefined
  ): Promise<Question[]>[] {

    let promises: Promise<Question[]>[] = [];
    // Point of interests support different object kinds:
    let amountPerObjectKind: { [key: string]: number } = {};
    let amountWithoutObjectKind = 0;
    configsOfType.forEach(c => {
      if (!c.objectKind) {
        amountWithoutObjectKind += c.amount;
      } else {
        if (c.objectKind in amountPerObjectKind) {
          amountPerObjectKind[c.objectKind] += c.amount
        } else {
          amountPerObjectKind[c.objectKind] = c.amount
        }
      }
    });
    if (amountWithoutObjectKind > 0) {
      promises.push(
          tryOrElse(mapPromiseArray(requestCreationFunc(region, amountWithoutObjectKind, undefined), questionMapper),
          this.buildFallbackPromise(fallback, region, amountWithoutObjectKind))
      );
    } else if (amountPerObjectKind) {
      promises.push(
          tryOrElse(mapPromiseArray(requestCreationFunc(region, undefined, amountPerObjectKind), questionMapper),
            this.buildFallbackPromise(fallback, region, Object.values(amountPerObjectKind).reduce((a, b) => a+b, 0)))
      );
    }

    return promises;
  }
}
