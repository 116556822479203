import React from "react";
import '../styles/_explanationPopup.scss';
import mouseScroll from '../img/mouse-scroll.png';
import mouseClick from '../img/mouse-click.png';
import gameScherm from '../img/game-scherm.png';

export interface ExplanationPopupState {
    open: boolean
}

export interface ExplanationPopupProps {
    mouse: boolean;
    game: boolean;
    open: boolean
}

export class ExplanationPopup extends React.Component<ExplanationPopupProps, ExplanationPopupState> {
    constructor(props: ExplanationPopupProps) {
        super(props);
        this.state = {
            open: true
        }
    }

    render() {
        return (
            <div className={`${this.props.open ? 'explanationPopup' : 'closed'} ${this.state.open ? '':'closed'}`}>

                    <div className="heading">
                        <h2>{this.props.mouse ? 'MUIS GEBRUIK' : 'GAME SCHERM'}</h2>
                    </div>
                    <div className="visual">
                        {this.props.mouse ?
                            <>
                                <div className="visual-row">
                                    <div className="left">
                                        <img src={mouseScroll} alt=""/>
                                    </div>
                                    <div className="right">
                                        <h2>SCROLL WHEEL</h2>
                                        <p>Gebruik het scroll wheel om in en uit te zoomen op de kaart.</p>
                                    </div>
                                </div>
                                <div className="visual-row">
                                    <div className="left">
                                        <img src={mouseClick} alt=""/>
                                    </div>
                                    <div className="right">
                                        <h2>LINKERMUIS KNOP</h2>
                                        <p>Klik op de linkermuis knop
                                            om de locatie te kiezen.
                                            Houdt de linkermuisknop ingedrukt om de kaart te slepen.
                                        </p>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="visual-full">
                                    <img src={gameScherm} alt=""/>
                                </div>
                            </>
                        }
                    </div>
            </div>
        );
    }
}