import React, {Component} from 'react';
import {delay} from './util/Helpers';

import config from './config.json';
import {MathUtil} from './util/MathUtil';
import {FormatUtil} from './util/FormatUtil';

interface QuestionResultProps {
    distance: number,
    time: number,
    score: number
}

interface QuestionResultState {
    currentDistance: number,
    currentTime: number,
    currentScore: number,
}

export class QuestionResult extends Component<QuestionResultProps, QuestionResultState>{

    private static readonly MAX_SCORE = config.mission.scoring.time.maxScore + config.mission.scoring.distance.maxScore;

    private static readonly DEFAULT_STATE: QuestionResultState = {
        currentDistance: 0,
        currentTime: 0,
        currentScore: QuestionResult.MAX_SCORE,
    };

    constructor(props: QuestionResultProps) {
        super(props);

        this.state = {...QuestionResult.DEFAULT_STATE};
    }

    componentDidMount() {
        this.showResultAnimation().then();
    }

    async showResultAnimation(): Promise<any> {

        this.setState({...QuestionResult.DEFAULT_STATE})

        // Await the standard delay
        await delay(config.mission.answerAnimation.delayBeforeAnswerPinDrops + config.mission.answerAnimation.delayBeforeCircle);

        const animationLength = config.mission.answerAnimation.circleAnimationLength;
        const startTime = performance.now();
        let time = 0;
        while (time < animationLength) {
            let alpha = time / animationLength;
            this.setState({
                currentDistance: this.props.distance * alpha,
                currentTime: this.props.time * alpha,
                currentScore: MathUtil.interpolate(QuestionResult.MAX_SCORE, this.props.score, alpha),
            });
            await delay(16);
            time = performance.now() - startTime;
        }
        this.setState({
            currentDistance: this.props.distance,
            currentTime: this.props.time,
            currentScore: this.props.score,
        });
    }

    render() {
        return (
            <div className="w-48 h-32">
                <p>
                    <span>Afstand:&nbsp;</span>
                    { this.props.distance < 0
                      ? (<span className="font-bold">-</span>)
                      : (<span><span className="font-bold">{FormatUtil.formatDistance(this.state.currentDistance)}</span><span></span></span>)
                    }
                </p>
                <p>
                    <span>Tijd:&nbsp;</span>
                    <span className="font-bold">{FormatUtil.formatTime(this.state.currentTime)}</span><span></span>
                </p>
                <p>
                    <span>Score:&nbsp;</span>
                    <span className="font-bold">{FormatUtil.formatScore(this.state.currentScore)}</span><span></span>
                </p>
            </div>
        )
    }

}
