import {useNavigate, useParams} from 'react-router-dom';
import {Mission, MissionMode} from '../Mission';
import config from '../config.json';
import {BackendService} from "../data/BackendService";
import {useEffect} from "react";
import {ConfigService} from '../config/ConfigService';

const MissionPage = () => {
    let backend = BackendService.getInstance();

    const navigate = useNavigate();

    const params = useParams()
    const mode = params["mode"] === "droog" ? MissionMode.Droog : MissionMode.Nat;
    const region = params["region"]!!.replace(' ', '-');
    const missionLevel = parseInt(params["level"]!!);
    useEffect(() => {

        const mode = params["mode"] === "droog" ? MissionMode.Droog : MissionMode.Nat;
        const region = params["region"]!!.replace(' ', '-');
        const missionLevel = parseInt(params["level"]!!);

        if (mode == null || region == null || missionLevel == null) return;

        missionGuard(mode, region, missionLevel);

        //router guard for missions based on highscore & mission lock
        function missionGuard(mode: MissionMode, region: string, level: number) {

            const oneStarScore = config.mission.stars.oneStar;

            // Check if there is a mission lock, and if there is if it's for this level:
            if (backend.missionRestriction.value) {
                if (backend.missionRestriction.value !== determineMissionUid(mode, region, level)) {
                    navigate("/", {replace: true});
                    return;
                }
            } else {

                if (level === 1 || oneStarScore === 0) return;
                backend.getPlayerMe().then(r => {


                    let previousMission = determineMissionUid(mode, region, level - 1);
                    if (r.progress.missionProgress.find(m => m.missionUid === previousMission && m.score >= oneStarScore)) {
                        console.log('allowed')
                    } else {
                        console.log('not allowed')
                        navigate("/", {replace: true});
                        return;
                    }
                })
            }
        }
    });

    return (<Mission scoreSettings={config.mission.scoring}
                     questionConfigs={ConfigService.getInstance().getMissionConfig(mode, missionLevel)}
                     mode={mode}
                     region={region}
                     missionLevel={missionLevel}
    />);
}

function determineMissionUid(mode: MissionMode, region: string, level: number): string {

    const modeLetter = mode === MissionMode.Droog ? "D" : "N";
    return modeLetter + '_' + region + '_' + level;

}

export default MissionPage;
