import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import config from '../config.json'
import {BehaviorSubject, max, timeout} from 'rxjs';

export interface Login {
    token: string,
    expiresIn: number,
    userUid: string,
    /**
     * URL to which the user should be redirected when done with the game (or mission if playing in a restricted state)
     */
    returnUrl: string,
}

export interface UserSession {
    playerUid: string,
    start_time: string,
    uid: string
}

export interface PlayThrough {
    uid: string,
    missionUid: string
    sessionUid: string
    playerUid: string
    startTime: string
    endTime: string
    score: string,
    result: string,
    objectives: []
}

export interface PlayerData {

    uid: string
    nickname: string,
    progress: {
        missionProgress: [
            {
                missionUid: string,
                score: number
            }
        ]
    }
}

export interface Mission {
    uid: string
    name: string
    description: string
    tags: {
        mode: string
        difficulty: string
        region: string
    }
}

export interface CategoryHighscores {
    category: MissionCategory
    highscores: Highscore[]
    playerPosition?: number
}
export interface HighscorePositions {
    missionHighscorePositions: {[key: string]: number};
    categoryHighscorePositions: {[key: string]: number};
}

export interface MissionCategory {
    uid: string
    name: string
    missionTags: MissionTags
    aggregationMethod: string
    objectivePropertyKey: any
    objectivePropertyAggregationMethod: any
}

export interface MissionTags {
    mode: string
}

export interface Highscore {
    playerNickname: string
    playerUid: string
    score: number
}

const TOKEN_VALID_UNTIL_KEY = 'tokenValidUntil';
const USER_SESSION_KEY = 'userSession';
const SESSION_LAST_ACTIVE_KEY = 'sessionLastActive';
const PLAYTHROUGH_KEY = 'playthrough';
const MISSION_RESTRICTION_KEY = 'missionRestriction';
const TOKEN_KEY = 'token';
const USER_UID_KEY = 'userUid';
const RETURN_URL = "returnUrl";

export class BackendService {

    private static instance: BackendService;

    private static readonly SESSION_TIMEOUT = 25*60;

    private token = sessionStorage.getItem(TOKEN_KEY);
    private userUid = sessionStorage.getItem(USER_UID_KEY);
    private tokenValidUntil = Number(sessionStorage.getItem(TOKEN_VALID_UNTIL_KEY) ?? 0);
    private userSession?: UserSession = JSON.parse(String(sessionStorage.getItem(USER_SESSION_KEY)));
    private sessionLastActive?: number = Number(sessionStorage.getItem(SESSION_LAST_ACTIVE_KEY) ?? 0);

    private playthrough?: PlayThrough = JSON.parse(String(sessionStorage.getItem(PLAYTHROUGH_KEY))) || {};
    private missionUid = '';

    public isInitialized = new BehaviorSubject<boolean>(false);
    public isInitializing = new BehaviorSubject<boolean>(false);
    public userSignedIn = new BehaviorSubject<boolean>(this.isSignedIn());
    public userSessionActive = new BehaviorSubject<boolean>(this.hasSession());
    public loggedInPlayer = new BehaviorSubject<PlayerData|null>(null);
    public missionRestriction = new BehaviorSubject<string|null>(sessionStorage.getItem(MISSION_RESTRICTION_KEY));
    public returnUrl = new BehaviorSubject<string|null>(sessionStorage.getItem(RETURN_URL))
    private loggedInPlayerRequiredRefresh: boolean = false;

    public static getInstance(): BackendService {
        if (!BackendService.instance) {
            BackendService.instance = new BackendService();
        }
        return BackendService.instance;
    }

    public initialize(token: string | null, missionRestriction: string | null) {

        if (this.isInitialized.value) return;

        this.isInitializing.next(true);

        if (token) {
            this.tokenLogin(token, missionRestriction)
              .then(_ => {
                  this.isInitializing.next(false);
                  this.isInitialized.next(true);
              })
              .catch(_ => {
                  this.isInitializing.next(false);
                  this.isInitialized.next(true);
              })
        } else {
            // Nothing to do
            this.isInitializing.next(false);
            this.isInitialized.next(true);
        }

    }

    /**
     * One-time-token login used in the LTI login process (e.g. Moodle)
     * @param token
     * @param missionRestriction if this login session is restricted to a certain mission
     */
    async tokenLogin(token: string, missionRestriction: string|null): Promise<Login> {

        // So that we don't use an old session while logging in the new one:
        this.logout();

        let res = await axios.post<Login>(process.env.REACT_APP_BACKEND_URL + '/auth/login/token', {
            "token": token
        });
        return this.handleLogin(res, missionRestriction);
    }

    //login user based on usr & psw
    async login(username: string, password: string, startPath?:string): Promise<Login> {

        let res = await axios.post<Login>(process.env.REACT_APP_BACKEND_URL + '/auth/login/basic', {

            "username": username,
            "password": password
        });
        return this.handleLogin(res, undefined, startPath)
    }

    private handleLogin(loginResponse: AxiosResponse<Login>, missionRestriction: string|null = null, startPath?: string): Login {
        if (loginResponse.status !== 200) {
            throw new Error(`Failed to login, status ${loginResponse.status}: ${loginResponse.statusText}, message: '${loginResponse.data}'`);
        } else {
            this.token = loginResponse.data.token
            this.userUid = loginResponse.data.userUid
            this.tokenValidUntil = BackendService.now() + loginResponse.data.expiresIn * 1000;
            this.returnUrl.next(loginResponse.data.returnUrl)

            sessionStorage.setItem(TOKEN_KEY, this.token);
            sessionStorage.setItem(USER_UID_KEY, this.userUid);
            sessionStorage.setItem(TOKEN_VALID_UNTIL_KEY, this.tokenValidUntil.toString());
            if (missionRestriction) {
                sessionStorage.setItem(MISSION_RESTRICTION_KEY, missionRestriction);
            }
            if (loginResponse.data.returnUrl) {
                sessionStorage.setItem(RETURN_URL, loginResponse.data.returnUrl);
            } else {
                sessionStorage.removeItem(RETURN_URL);
            }
            this.missionRestriction.next(missionRestriction ?? null);

            this.clearSession();
            this.userSignedIn.next(this.isSignedIn());

            return loginResponse.data;
        }
    }

    public logout() {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(USER_UID_KEY);
        sessionStorage.removeItem(TOKEN_VALID_UNTIL_KEY);
        sessionStorage.removeItem(MISSION_RESTRICTION_KEY);

        if (this.userSignedIn.value) {
            this.userSignedIn.next(false);
        }
        if (this.loggedInPlayer.value) {
            this.loggedInPlayer.next(null);
            this.loggedInPlayerRequiredRefresh = true;
        }
        if (this.missionRestriction.value) {
            this.missionRestriction.next(null);
        }
        this.clearSession();
    }

    // noinspection JSMethodCanBeStatic
    private clearSession() {
        sessionStorage.removeItem(USER_SESSION_KEY);
        sessionStorage.removeItem(SESSION_LAST_ACTIVE_KEY);
        sessionStorage.removeItem(PLAYTHROUGH_KEY);
    }

    // check if local storage contains token with expiration
    isSignedIn(): boolean {
        return !!this.token && this.tokenValidUntil > BackendService.now() + (15 * 60 * 1000);
    }

    async getPlayerMe(forceRefresh: boolean = false): Promise<PlayerData> {
        if (!forceRefresh && !this.loggedInPlayerRequiredRefresh && this.loggedInPlayer.getValue() != null) {
            return this.loggedInPlayer.getValue()!!;
        }

        try {
            let res = await axios.get<PlayerData>(process.env.REACT_APP_BACKEND_URL + '/player/me', this.defaultConfig());
            if (res.status !== 200) {
                await this.logout();
                setTimeout(_ => window.location.reload(), 1);
                // noinspection ExceptionCaughtLocallyJS
                throw new Error(`Failed to get current player, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);

            } else {
                this.updateLoggedInPlayer(res.data);
                this.loggedInPlayerRequiredRefresh = false;
                return res.data;
            }
        } catch (e) {
            // If this request failed, our session is broken, let's log out
            await this.logout();
            // We'll refresh after the other classes had a chance the handle the logout
            setTimeout(_ => window.location.reload(), 1);
            throw e;
        }
    }

    private updateLoggedInPlayer(player: PlayerData | null) {
        this.loggedInPlayer.next(player);
    }

    async getMissions(): Promise<Mission[]> {
        const res = await axios.get<Mission[]>(process.env.REACT_APP_BACKEND_URL + '/mission', this.defaultConfig())
        if (res.status >= 300) {
            throw new Error(`Failed to get missions, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            return res.data
        }
    }

    //start user session
    async startUserSession(): Promise<UserSession> {
        if (this.hasSession()) {
            console.warn("User already has a session, not starting a new one!");
            return this.userSession!!;
        }
        let res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/session/start', {}, this.defaultConfig());
        if (res.status !== 200) {
            throw new Error(`Failed to start session, status ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            sessionStorage.setItem(USER_SESSION_KEY, JSON.stringify(res.data));
            this.userSession = res.data;
            this.markSessionActive();
            this.userSessionActive.next(this.hasSession());
            return res.data;
        }
    }

    private markSessionActive() {
        const now = BackendService.now();
        this.sessionLastActive = now;
        sessionStorage.setItem(SESSION_LAST_ACTIVE_KEY, now.toString());
    }

    private static now(): number {
        return new Date().valueOf();
    }

    hasSession(): boolean {
        return !!this.userSession && !!this.sessionLastActive &&
            this.sessionLastActive > (BackendService.now() - (BackendService.SESSION_TIMEOUT * 1000))
    }

    //send post on mission start
    async missionStartPlayThrough(mode: string, region: string, level: number) {

        this.missionUid = this.determineMissionUid(mode, region, level);
        let res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/mission-playthrough/start', {
            sessionUid: this.userSession!!.uid,
            missionUid: this.missionUid
        }, this.defaultConfig());
        if (res.status !== 200) {
            throw new Error(`Failed to send mission start, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            sessionStorage.setItem(PLAYTHROUGH_KEY, JSON.stringify(res.data));
            this.playthrough = res.data;
        }
    }

    determineMissionUid(mode: string, region: string, level: number): string {
        if (mode === 'Nat') {
            mode = 'N'
        }
        if (mode === 'Droog') {
            mode = 'D';
        }
        return String(mode + '_' + region.replace(' ', '-') + '_' + level);
    }

    //send post every time an answer has been given in the mission
    async missionSubmitObjective(questionObject: any | null, score: number, properties: { distance: string, time: string }) {

        this.markSessionActive()
        let res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/mission-playthrough/' + this.playthrough!!.uid + '/objective', {
            name: questionObject?.data?.wegnummer || questionObject?.data?.objectName || questionObject?.data?.objectName || questionObject?.data?.name || questionObject?.data?.fairwayName,
            score: score,
            properties: {
                distance: properties.distance,
                time: properties.time
            }
        }, this.defaultConfig());
        if (res.status !== 200) {
            throw new Error(`Failed to send submit objective request, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            return res.data
        }
    }

    //send post to api if user has finished a game
    async missionEnd(score: number, maxScore: number) {

        this.markSessionActive();

        let result = 'FAILED';
        if (score >= config.mission.stars.oneStar) {
            result = 'COMPLETED'
        }
        let res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/mission-playthrough/' + this.playthrough!!.uid + '/end', {
            result: result,
            score: score,
            maxScore: maxScore
        }, this.defaultConfig());
        if (res.status !== 200) {
            throw new Error(`Failed to send end request, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            // This playthrough is now done
            this.playthrough = undefined;
            sessionStorage.removeItem(PLAYTHROUGH_KEY);
            this.loggedInPlayerRequiredRefresh = true
        }
    }

    //send post to api if user aborts game
    async missionAbort() {

        this.markSessionActive();

        let res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/mission-playthrough/' + this.playthrough!!.uid + '/end', {
            result: 'ABORTED',
        }, this.defaultConfig());
        if (res.status !== 200) {
            throw new Error(`Failed to send end request, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            // This playthrough is now done
            this.playthrough = undefined;
            sessionStorage.removeItem(PLAYTHROUGH_KEY);
            this.loggedInPlayerRequiredRefresh = true
        }
    }

    async getHighscorePositions(){
        return await axios.get<HighscorePositions>(process.env.REACT_APP_BACKEND_URL + '/highscores/player/me/',
            this.defaultConfig())
    }

    async getHighscoresCategory(categoryName: string, players?: string[]): Promise<CategoryHighscores> {

        let res = await axios.get<CategoryHighscores>(process.env.REACT_APP_BACKEND_URL + '/highscores/category/' + categoryName,
            {
                ...this.defaultConfig(),
                params: {
                    players: players?.join(","),
                }
            });
        if (res.status !== 200) {
            throw new Error(`Failed to get highscores, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            return res.data;
        }
    }

    getHighscoreModeSum(mode:string, players?: string[]): Promise<CategoryHighscores> {
        return this.getHighscoresCategory(mode+'_sum', players);
    }

    getHighscoreModeAvg(mode:string, players?:string[]): Promise<CategoryHighscores> {
        return this.getHighscoresCategory(mode + "_avg", players);
    }

    async generateNickname() {
        let res = await axios.get<string>(process.env.REACT_APP_BACKEND_URL + '/player/nickname/generate',
            this.defaultConfig());
        if (res.status !== 200) {
            throw new Error(`Failed to get current player, status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            return res.data;
        }
    }

    async setPlayerNickname(nickname: string): Promise<PlayerData> {
        let res = await axios.patch<PlayerData>(process.env.REACT_APP_BACKEND_URL + '/player/me', {
            nickname: nickname
        }, this.defaultConfig());
        if (res.status !== 200) {
            throw new Error(`Failed to update player (set nickname), status: ${res.status}: ${res.statusText}, message: '${res.data}'`);
        } else {
            this.updateLoggedInPlayer(res.data);
            return res.data;
        }
    }

    private defaultConfig(): AxiosRequestConfig {
        return {
            headers: {
                Authorization: 'Bearer ' + this.token
            }
        }
    }


}
