import React from "react";
import '../styles/_vooruithang.scss';
import {Link} from "react-router-dom";
import score from '../img/score.svg'
export class Score extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div className="vooruitgang-button">
                    <img src={score} alt=""/>
                    <Link to={'score'}>
                        <div className="title">SCORE</div>
                    </Link>
                </div>
            </div>
        )
    }
}
