import {Component} from 'react';
import {delay} from './util/Helpers';
import Color from 'color';
import {MathUtil} from "./util/MathUtil";

interface QuestionTimerProps {
    totalTime: number,
    paused: boolean,
    colorMap: ColorPoint[],
    pausedColor: string,
    timerExpired: () => any,
}

interface ColorPoint {
    percentage: number,
    color: string,
}

interface QuestionTimerState {
    timeProgress: number,
    time: number,
    color: string,
}


export class QuestionTimer extends Component<QuestionTimerProps, QuestionTimerState> {

    constructor(props: QuestionTimerProps) {
        super(props);

        this.state = {
            timeProgress: 0,
            time: 0,
            color: this.props.colorMap[0].color,
        }
    }


    componentDidMount() {
        this.runTimer().then()
    }

    interpolateColors(start: string, end: string, progress: number): string {
        const startColor = Color(start);
        const endColor = Color(end);

        const hue = MathUtil.interpolate(startColor.hue(), endColor.hue(), progress);
        const sat = MathUtil.interpolate(startColor.saturationl(), endColor.saturationl(), progress);
        const lightness = MathUtil.interpolate(startColor.lightness(), endColor.lightness(), progress);

        return Color.hsl(hue, sat, lightness).string();
    }

    async runTimer() {

        const sortedColorMap = [...this.props.colorMap].sort((a, b) => a.percentage - b.percentage);

        const startTimeMs = performance.now();
        let timeMs = 0;
        while (timeMs < this.props.totalTime * 1000 && !this.props.paused) {
            const timeProgress = timeMs / (this.props.totalTime * 1000);

            const endColorIdx = sortedColorMap.findIndex(c => c.percentage > timeProgress);
            const startColor = endColorIdx === 0 ? sortedColorMap[0] : sortedColorMap[endColorIdx - 1];
            const endColor = sortedColorMap[endColorIdx];
            const colorProgress = (timeProgress - startColor.percentage) / (endColor.percentage - startColor.percentage);

            this.setState({
                timeProgress: timeProgress,
                color: this.interpolateColors(startColor.color, endColor.color, colorProgress),
                time: timeMs / 1000,
            })
            timeMs = performance.now() - startTimeMs;
            await delay(16);
        }
        if (!this.props.paused) {
            this.props.timerExpired();
            this.setState({
                timeProgress: 1,
                time: this.props.totalTime,
            });
        }
    }

    reset() {
        this.setState({
            timeProgress: 0,
        });
        setTimeout(() => this.runTimer().then(), 0);
    }

    render() {
        return (
            <div id="question-timer" className="bg-backdrop rounded-lg m-1 h-6">
                <div className="rounded-lg h-full" style={{
                    backgroundColor: this.props.paused ? this.props.pausedColor : this.state.color,
                    width: ((1 - this.state.timeProgress) * 100) + "%"
                }}/>
            </div>
        )
    }

}
