import React from 'react';
import '../styles/_leaderboard.scss'
import {Link} from 'react-router-dom';
import {LeaderboardContent} from '../components/LeaderboardContent';
import {PreviousButton} from "../components/PreviousButton";
import score from "../img/score.svg";
import blueIllustration from '../img/blue-illustration-top.svg'
import blueIllustrationBottom from '../img/blue-illustration-bottom.svg'

export interface LeaderBoardState {
    currentTab: string
}

export interface LeaderBoardProps {

}

export class LeaderBoard extends React.Component<LeaderBoardProps, LeaderBoardState> {

    constructor(props: LeaderBoardProps) {
        super(props);
        this.state = {
            currentTab: 'droog',
        }
    }

    setStateTabDroog() {
        this.setState({
            currentTab: 'droog'
        });
    }

    setStateTabNat() {
        this.setState({
            currentTab: 'nat'
        });
    }
    renderLeaderboardContent(){
        return (
            <LeaderboardContent mode={this.state.currentTab}/>
        )
    }

    render() {
        return (
            <div className={'leaderboard-page'}>
                <PreviousButton previousUrl={'../score'}/>
                <div className="illustration-top">
                    <img src={blueIllustration} alt=""/>
                </div>
                <div className="lb-score-button">
                    <img src={score} alt=""/>
                    <Link to={'../score'}>
                        <div className="title">SCORE</div>
                    </Link>
                </div>
                <h1 className="heading">Leaderboard</h1>

                <div className="leaderboard-buttons">
                    <div className="lb-nav">
                        <button className={'droog-button'} onClick={() => this.setStateTabDroog()}>DROOG</button>
                        <button className={'nat-button'} onClick={() => this.setStateTabNat()}>NAT</button>
                    </div>
                    {this.renderLeaderboardContent()}
                </div>
                <div className="illustration-bottom illu-lb">
                    <img src={blueIllustrationBottom} alt=""/>
                </div>
            </div>
        );
    }
}
