import React, {CSSProperties} from 'react';
import {GeoDataService} from '../data/GeoDataService';
import {BackendService} from '../data/BackendService';
import {ConfigService} from '../config/ConfigService';
import {MissionMode} from '../Mission';
import {RegionMeta, RegionMetaResponse} from '../data/GeoData';

interface MissionTestState {
    missionTestResults: MissionTestResult[],
}

interface MissionTestResult {
    mode: string,
    regionCode: string,
    regionName: string,
    level: number,
    geoApiStatus: Status,
    progressApiStatus: Status
}

enum Status { Ok = "OK", Error = "ERROR", Loading = "LOADING"}

export class MissionTester extends React.Component<any, MissionTestState> {

    state: MissionTestState = {
        missionTestResults: [],
    }
    private geoApi = GeoDataService.getInstance();
    private backend = BackendService.getInstance();
    private configService = ConfigService.getInstance();

    componentDidMount() {

        this.tryLoadAllMissions();
    }

    private async tryLoadAllMissions() {
        await this.tryLoadDroogMissions();
        await this.tryLoadNatMissions();
    }

    private async tryLoadDroogMissions() {
        const droogRegions = await this.geoApi.getDroogRegions();
        this.initializeRegions("Droog", droogRegions.regions);
        await this.tryLoadMissions(MissionMode.Droog, droogRegions);
    }

    private async tryLoadNatMissions() {
        const natRegions = await this.geoApi.getNatRegions();
        this.initializeRegions("Nat", natRegions.regions);
        await this.tryLoadMissions(MissionMode.Nat, natRegions);
    }

    private initializeRegions(mode: string, regions: RegionMeta[]) {
        this.setState({
            missionTestResults: [...this.state.missionTestResults, ...regions.flatMap(r => [1, 2, 3].map(i => ({
                mode,
                regionCode: r.code,
                regionName: r.name,
                level: i,
                progressApiStatus: Status.Loading,
                geoApiStatus: Status.Loading,
            })))]
        });
    }

    private async tryLoadMissions(mode: MissionMode, droogRegions: RegionMetaResponse) {

        const progressApiMissions = await this.backend.getMissions();

        for (let region of droogRegions.regions) {
            for (let i = 1; i <= 3; i++) {
                const missionConfig = this.configService.getMissionConfig(mode, i);
                let geoApiStatus = Status.Loading;
                try {
                    const geoApiResult = await this.geoApi.buildQuestionPromise(region.code, missionConfig);
                    geoApiStatus = geoApiResult.length === 10 ? Status.Ok : Status.Error;
                } catch (e) {
                    geoApiStatus = Status.Error
                }
                const missionUid = this.backend.determineMissionUid(mode === MissionMode.Droog ? "Droog" : "Nat", region.code, i);
                const progressApiStatus = progressApiMissions.find(m => m.uid === missionUid) ? Status.Ok : Status.Error;
                this.updateApiStatus(mode === MissionMode.Droog ? "Droog" : "Nat", region.code, i, geoApiStatus, progressApiStatus);
            }
        }
    }

    private updateApiStatus(mode: string, regionCode: string, level: number, geoApiStatus: Status, progressApiStatus: Status) {
        this.setState({
            missionTestResults: this.state.missionTestResults.map(m => {
                if (m.mode === mode && m.regionCode === regionCode && m.level === level) {
                    return {...m, geoApiStatus, progressApiStatus};
                }
                return m;
            })
        });
    }

    private static determineStyle(status: Status): CSSProperties {
        switch (status) {
            case Status.Ok: return {color: "green", fontWeight: "bold"};
            case Status.Error: return {color: "red", fontWeight: "bold"};
            case Status.Loading: return {color: "gray"};

        }
    }

    render() {
        return (
            <div className={"my-0 mx-auto max-w-3xl text-center"}>
                <h1 className={"heading pb-2"}>Mission tester</h1>
                <p>This page tries to load all missions, and shows if any of them can't be loaded. Useful for debugging purposes</p>

                <table className={"pt-2 table table-auto w-full"}>
                    <thead className={"table-header-group"}>
                        <tr>
                            <th>Mode</th>
                            <th>Regio</th>
                            <th>Niveau</th>
                            <th>Locatie API</th>
                            <th>Progress API</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.missionTestResults.map((mtr, i) => (
                        <tr key={"mtr" + i}>
                            <td>{mtr.mode}</td>
                            <td>{mtr.regionCode}</td>
                            <td>{mtr.level}</td>
                            <td style={MissionTester.determineStyle(mtr.geoApiStatus)}>{mtr.geoApiStatus}</td>
                            <td style={MissionTester.determineStyle(mtr.progressApiStatus)}>{mtr.progressApiStatus}</td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>
        );
    }
}
