import React from "react";
import {PathOfInterest} from '../data/GeoData';
import iceTrajectory from "../img/iceTrajectory.svg";
import harbour from '../img/harbour.svg';
interface PathOfInterestProps {
    pathOfInterest: PathOfInterest
}
export class PathOfInterestSign extends React.Component<PathOfInterestProps, any> {

    render() {
        switch (this.props.pathOfInterest.objectKind) {
            case 'icetrajectory':
                return (
                    <div className={'longblue'}>
                        <div className="image">
                            <img src={iceTrajectory} alt="iceTrajectory"/>
                        </div>
                        <div className="title">
                            {this.props.pathOfInterest.name}
                        </div>
                    </div>
                );
            case 'vinharbour':
            case 'nwbharbour':
                return (
                    <div className={'square-gray'}>
                        <div className="image">
                            <img src={harbour} alt="Haven"/>
                        </div>
                        <div className="title">
                            {this.props.pathOfInterest.name}
                        </div>
                    </div>
                )
        }
    }
}
