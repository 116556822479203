import {BBox, Feature, FeatureCollection, Point, Polygon, Position} from 'geojson';
import distance from "@turf/distance";
import circle from "@turf/circle";
import centerOfMass from "@turf/center-of-mass";
import {LngLatBounds, LngLatBoundsLike} from 'mapbox-gl';
// import mapboxgl, {LngLatBounds, LngLatBoundsLike, LngLatLike} from 'mapbox-gl';

import config from '../config.json';

export class GeoUtil {

  public static readonly defaultBounds: LngLatBounds = LngLatBounds.convert(
      [config.map.defaultBounds[0][0], config.map.defaultBounds[0][1], config.map.defaultBounds[1][0], config.map.defaultBounds[1][1]]
  );
  public static readonly defaultMaxBounds: LngLatBounds = LngLatBounds.convert(
      [config.map.defaultMaxBounds[0][0], config.map.defaultMaxBounds[0][1], config.map.defaultMaxBounds[1][0], config.map.defaultMaxBounds[1][1]]
  );

  static calculateDistance(from: Position, to: Position): number {

    return distance(from, to, {units: "meters"});
  }

  static buildCircle(center: Position, radius: number): Feature<Polygon> {

    return circle(center, radius, {
      units: "meters",
      steps: 180,
    });

  }

  static bboxContains(bounds: LngLatBoundsLike, position: Position) {
    return LngLatBounds.convert(bounds).contains([position[0], position[1]]);
  }

  static toLngLatBounds(bbox: BBox): LngLatBounds {
    return LngLatBounds.convert([bbox[0], bbox[1], bbox[2], bbox[3]]);
  }

  static wrappingBbox(bboxes: LngLatBoundsLike[]) {
    if (!bboxes || bboxes.length === 0) return new LngLatBounds([0, 0, 1, 1]);
    return bboxes
        .map(bb => LngLatBounds.convert(bb))
        .reduce((bb1, bb2) => bb1.extend(bb2))
  }

  static centerOfMass(data: Feature | FeatureCollection): Point {
    return centerOfMass(data).geometry
  }
}
