import {useParams} from "react-router-dom";
import {MissionMode} from "./Mission";
import RegionSelection from './pages/RegionSelection';

const RegionSelectionOptions = () => {
    const params = useParams()
    return (<RegionSelection
        mode={params["mode"] === "droog" ? MissionMode.Droog : MissionMode.Nat}
    />);
}
export default RegionSelectionOptions
