import React from "react";
import {Link} from "react-router-dom";
import '../styles/start.scss';
import '../styles/_hamburger.scss';
import {HamburgerMenu} from "../components/HamburgerMenu";
import {Score} from "../components/Score";
import {WelcomeStart} from "../components/WelcomeStart";
import {BackendService} from "../data/BackendService";
import logo from '../img/locatie-spotter-logo.svg'
import droog from '../img/droog-shape-2.svg'
import rwsLogo from '../img/logo-nl.svg'
import natShape from '../img/nat-shape.svg'
export interface StartState {
    nickname: string
}
export interface StartProps {}
export class Start extends React.Component<StartProps, StartState> {
    private backend = BackendService.getInstance();


    constructor(props: StartProps) {
        super(props);
        this.state = {
            nickname: ''
        }
    }

    componentDidMount() {
        this.backend.startUserSession().then()
        this.backend.getPlayerMe().then(r => {
            this.setState({
                nickname: r.nickname
            })
        });

    }
    render() {
        return (
            <div className={'start-bg'}>
                <div className="nat-shape">
                    <img src={natShape} alt=""/>
                </div>
                <HamburgerMenu isStart={true}/>
                {this.state.nickname ? <WelcomeStart/> : <><div className="absolute logo">
                    <Link to="/">
                        <img src={logo} alt="locatie spotter logo"/>
                    </Link>
                </div></>}
                <div className="flex justify-center items-center h-screen">
                    <Link to={this.state.nickname ? '/mode': "/name-generator"}
                          className="start-button start-title z-10 mx-auto bg-rws-yellow py-1 align-middle rounded-full text-center text-2xl table">
                        <div className="table-cell text-center align-middle">Start</div>
                    </Link>
                </div>
                <div className="circle-container">
                    <div className="circle"></div>
                </div>
                <div>
                    <img className={'droog-shape'} src={droog} alt="droog shape"/>
                </div>
                <div className="overheid-logo">
                    <a rel="noreferrer" target='_blank' href={'https://rijkswaterstaat.nl'}>
                        <img src={rwsLogo} alt=""/>
                    </a>
                </div>
                <Score/>
            </div>
        )
    }
}
