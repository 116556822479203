import {Hectopunt} from "../data/GeoData";
import {Component} from "react";
import '../styles/_sign.scss';
export interface HectometerSignProps {
  hectopunt: Hectopunt,
  size: 'large' | 'small'
}

const classesDefault = "inline-block bg-hectometer-sign-green p-1 rounded";
const classesLarge = "w-48 h-32";
const classesSmall = "w-28 h-auto";

export class HectometerSign extends Component<HectometerSignProps, {}> {

  wegType(): "A" | "N" {
    return this.props.hectopunt.wegnummer.startsWith("A") ? "A" : "N";
  }

  textSize(): string {
    return this.props.size === "large" ? "text-2xl" : "text-l";
  }

  render() {
    return (
      <div className={classesDefault + " " +
        (this.props.size === "large"
          ? classesLarge
          : classesSmall)
      }>
        <div className={"border-4 rounded border-white w-full h-full " + (this.props.size === "large" ? "p-2" : "")} >
          <div className="flex items-center">
            <div className={"inline-block border-2 rounded border-white p-1 m-1 " + this.textSize() + " " +
              (this.wegType() === "A" ? "bg-main-road-red text-white" : "bg-national-road-yellow text-black")}>
              {this.props.hectopunt.wegnummer}</div>
            <div className={"text-right text-white ml-2 " + this.textSize()}>{this.props.hectopunt.side}</div>
          </div>
          <div className={"text-white mx-1 mb-1 " + this.textSize()}>{ (this.props.hectopunt.distance / 10).toFixed(1)}</div>
        </div>
      </div>
    );
  }
}
