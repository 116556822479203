import React, {ReactNode} from "react";
import '../styles/_tips.scss';
import {BackendService} from "../data/BackendService";
import config from "../config.json";
import {ExplanationPopup} from "../components/ExplanationPopup";
import {PreviousButton} from "../components/PreviousButton";
import closePopUp from '../img/closePopUp.svg'
import grayStar from "../img/gray-star.svg";
import goldStar from "../img/gold-star.svg";
import mouse from '../img/mouse.svg';
import gameScherm from '../img/gamescherm-button.svg';
import blueIllustration from "../img/blue-illustration-top.svg";
import blueIllustrationBottom from "../img/blue-illustration-bottom.svg";
import {ConfigService} from "../config/ConfigService";
import Stars from "../components/Stars";
export interface TipsPageState {
    playerMissionPosition: number
    playerMissionScore: number
    currentMission: string
    tipsPopup: boolean
    popupOpen: boolean
    game: boolean,
    mouse: boolean
}

export interface TipsPageProps {
    tipsPopup?: boolean
    showMission?:boolean
    showBackButtonExplicitly?:boolean
}

export class TipsPage extends React.Component<TipsPageProps, TipsPageState> {
    currentUrl = window.location.pathname.split('/')

    backend = BackendService.getInstance();

    constructor(props: TipsPageProps) {
        super(props);
        this.state = {
            playerMissionScore: 0,
            playerMissionPosition: 0,
            currentMission: '',
            tipsPopup: true,
            popupOpen: false,
            game: false,
            mouse: false
        }
    }

    determinePopupState(popup: boolean) {
        this.setState({
            popupOpen: popup
        })
    }

    closePopup() {
        this.setState({
            popupOpen: false
        });
    }

    renderExplanationContent(mouse: boolean, game: boolean) {
        this.setState({
            mouse: mouse,
            game: game
        })
    }

    setPlayerMissionStats() {
        let mode;
        let region;
        region = this.currentUrl[3]
        if (this.currentUrl[2] === 'droog') {
            mode = 'D'
        } else if (this.currentUrl[2] === 'nat') {
            mode = 'N'
        }
        let url = mode + '_' + region + '_' + this.currentUrl[5]
        this.backend.getPlayerMe().then(r => {
            let mission = r.progress.missionProgress.find(m => m.missionUid === url);
            console.log(mission)
            this.setState({
                playerMissionScore: mission!!.score || 0,
                currentMission: mission!!.missionUid
            })
        })
    }

    async getMissionPosition() {
        this.backend.getHighscorePositions().then(pos => {
            let mission = Object.keys(pos.data.missionHighscorePositions).find(m => m === this.state.currentMission)
            console.log(mission)

            this.setState({
                playerMissionPosition: pos.data.missionHighscorePositions[mission!!]
            })
            console.log(pos.data.missionHighscorePositions[mission!!])
        })
    }

    componentDidMount() {
        this.setPlayerMissionStats();
        this.getMissionPosition().then()
    }

    render() {
        return (
            <div className={`${this.props.tipsPopup ? 'tips-page' : 'closed'} ${this.state.tipsPopup ? '' : 'closed'}`}>
                <span>
                        {this.props.showBackButtonExplicitly ?
                            <>
                                <PreviousButton previousUrl={'../'}/>
                            </>
                            :
                            <>
                            </>}
                    </span>
                <div className="tips-explain">
                    <div className={this.state.popupOpen ? 'tips-tools tools' : 'dont-show-tools'}>
                        <div className="closePopup" onClick={() => this.closePopup()}>
                            <img src={closePopUp} alt="close popup button"/>
                        </div>
                    </div>
                    <div className="explain-popup">
                        <ExplanationPopup open={this.state.popupOpen} game={this.state.game} mouse={this.state.mouse}/>
                    </div>
                </div>
                <div className="explain-buttons tip-explain-buttons">
                    <div className="next-button row-button button-blue" onClick={() => {
                        this.renderExplanationContent(true, false);
                        this.determinePopupState(true)
                    }}>
                        <img src={mouse} alt="mouse usage button"/>
                        <button
                            className="title">
                            MUIS GEBRUIK
                        </button>
                    </div>
                    <div className="next-button button-blue row-button" onClick={() => {
                        this.renderExplanationContent(false, true);
                        this.determinePopupState(true)
                    }}>
                        <img src={gameScherm} alt="gamescherm button"/>
                        <button
                            className="title">
                            GAME SCHERM
                        </button>
                    </div>
                </div>
                <div className="popup">
                    <div className={this.state.popupOpen ? 'tools' : 'dont-show-tools'}>
                        <div className="closePopup" onClick={() => this.closePopup()}>
                            <img src={closePopUp} alt="close popup"/>
                        </div>
                    </div>

                </div>
                <div className="illustration-top">
                    <img src={blueIllustration} alt="blue illustration"/>
                </div>
                <div className="tips">
                    <h1 className="heading">
                        Tips
                    </h1>
                    <p>
                        Locatiespotter is een spel bedoeld voor het testen van je parate areaalkennis. Door meer te spelen, zul
                        je meer locaties leren kennen en zul je uiteindelijk een betere score halen.<br/><br/>

                        Je kunt het spel het beste spelen met een muis die een scrollwiel heeft. Je zult merken dat het
                        daarmee een stuk eenvoudiger wordt om te spelen.<br/><br/>

                        <b>Tips:</b><br/>
                        Niveau 1 kun je spelen zonder in te zoomen. Hierdoor kun je sneller kiezen, waardoor je een
                        hogere score haalt.
                        Het is niet erg om een fout te maken, maar klik niet te snel door naar de volgende vraag.
                        bestudeer eerst het antwoord voor je verder gaat.
                        Focus niet op de score, maar concentreer zoveel mogelijk op het vergroten van je kennis. Daarna
                        komt die hoge score wel.
                    </p>
                    {this.props.showMission? <>
                        <div className="bottom-stats ">
                            <h3 className="title mr-5">Categorie: {this.currentUrl[2] + ' > Regio: ' + this.currentUrl[3]}</h3>
                            <div className="number mr-5">
                                {this.state.playerMissionPosition}
                            </div>
                            <div className="score mr-5">
                                <b>{this.state.playerMissionScore}</b>
                            </div>
                            <div className="stars mr-4">
                                <Stars score={this.state.playerMissionScore} />
                            </div>
                        </div></>:<></>}

                    <div className="illustration-bottom">
                        <img src={blueIllustrationBottom} alt=""/>
                    </div>
                </div>
            </div>
        );
    }
}
