import React from "react";
import '../styles/_info.scss';
import {PreviousButton} from "../components/PreviousButton";
import daan from '../img/daan.png';
import bas from '../img/bas-dado.png';
import bente from '../img/bente-van-beers.png';
import jonas from '../img/jonas.png';
import gametailorslogo from '../img/gametailors-logo.webp';
import preludens from '../img/Preludens-Vertical-Logo-RGB.png'
import blob from "../img/blob-top-right.svg";

export class InfoPage extends React.Component<any, any> {

    render() {
        return (
            <div className="info-page h-full w-full inline-block relative">
                <PreviousButton previousUrl={'./../'}/>
                <div className="illustration">
                    <img src={blob} alt="blob"/>
                </div>
                <h1 className="mt-16 heading w-full tex-center">
                    Info
                </h1>
                <p className={'paragraph'}>Je krijgt de volgende melding: “Op het Spui bij kilometer 1005 drijft een brandend jacht.” Weet jij direct waar dit is?<br/>
                    Of:<br/>
                    “Op de A15 Rechts bij 63,3 ligt een balk op de weg.” Op welke camera kan ik deze het snelst bekijken.
                    Parate areaalkennis bij  verkeersbegeleiding en objectbediening kan essentieel zijn voor een  vlotte en veilige doorstroming op onze (vaar)wegen. Leer op een leuke  en uitdagende manier het areaal kennen middels een Serious Game. Met de  Serious Game, genaamd “Locatiespotter”, doe je op een leuke en  uitdagende manier parate areaalkennis op. In opdracht  van VWM ontwikkelt CIV samen met VWM deze Locatiespotter.</p>
                <section className="credits">
                    <h2 className={'heading mt-20'}>Credits</h2>
                    <div className="credit-persons">
                        <div className="column-small">
                            <div className="image mb-5">
                                <a target={'_blank'} rel={"noreferrer"} href="https://preludens.nl/">
                                    <img src={preludens} alt="Preludens logo"/>
                                </a>
                            </div>
                            <div className="person">
                                <img src={daan} alt="Daan Groen"/>
                                <div>
                                    <div className="name">
                                        Daan Groen
                                    </div>
                                    <div className="function">
                                        Game designer
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column-big">
                            <div className="image">
                                <a rel="noreferrer" target={'_blank'} href="https://www.gametailors.com/">
                                    <img src={gametailorslogo} alt="Game Tailors logo"/>
                                </a>
                            </div>
                            <div>
                                <div className="person">
                                    <img src={bas} alt="Daan Groen"/>
                                    <div>
                                        <div className="name">
                                            Bas Dado
                                        </div>
                                        <div className="function">
                                            Game developer
                                        </div>
                                    </div>
                                </div>
                                <div className="person">
                                    <img src={bente} alt="Daan Groen"/>
                                    <div>
                                        <div className="name">
                                            Bente van Beers
                                        </div>
                                        <div className="function">
                                            Game/UI artist
                                        </div>
                                    </div>
                                </div>
                                <div className="person">
                                    <img src={jonas} alt="Daan Groen"/>
                                    <div>
                                        <div className="name">
                                            Jonas Østergaard den Hollander
                                        </div>
                                        <div className="function">
                                            Web developer
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
