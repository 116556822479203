import React, {ReactNode} from "react";
import {PreviousButton} from "../components/PreviousButton";
import '../styles/_score.scss';
import {Link} from "react-router-dom";
import {BackendService} from "../data/BackendService";
import config from "../config.json";
import {FormatUtil} from "../util/FormatUtil";
import leaderboardButton from '../img/leaderboard-button.svg'
import replay from "../img/replay.png";
import blueIllustration from '../img/blue-illustration-top.svg'
import blueIllustrationBottom from '../img/blue-illustration-bottom.svg'
import grayStar from "../img/gray-star.svg";
import goldStar from "../img/gold-star.svg";
import userStats from '../img/user-stats.svg';
import userStatsRev from '../img/user-stats-rev.svg';
import {ConfigService} from "../config/ConfigService";
import Stars from "../components/Stars";
export interface ScorePageState {
    nickname?: string,
    playerSum?: number,
    playerAvg?: number,
    playerStars: number
    progress: {
        missionProgress:
            {
                missionUid: string,
                score: number,
                missionName: string,
                position: number,
                mode?: string,
                region?: string,
                difficulty?: string,
            }[]
    }
}

export interface ScorePageProps {
}

export class ScorePage extends React.Component<ScorePageProps, ScorePageState> {
    private backend = BackendService.getInstance();

    constructor(props: ScorePageProps) {
        super(props);
        this.state = {
            nickname: '',
            playerSum: 0,
            playerAvg: 0,
            playerStars: 0,
            progress: {
                missionProgress: [
                    {
                        missionUid: '',
                        score: 0,
                        missionName: '',
                        position: 0,
                        mode: '',
                        region: '',
                        difficulty: '1'
                    }
                ]
            }
        }
    }

    //set state for played missions
    async setPlayedMissions() {
        const player = await this.backend.getPlayerMe();
        const missions = await this.backend.getMissions();
        const positions = await this.backend.getHighscorePositions();

        this.setState({
            progress: {
                missionProgress: player.progress.missionProgress.map(pm => (
                    {
                        missionUid: pm.missionUid,
                        score: pm.score,
                        missionName: String(missions.find(missions => missions.uid === pm.missionUid)!.name),
                        position: positions.data.missionHighscorePositions[pm.missionUid],
                    })
                )
            }
        });
    }

    componentDidMount() {
        this.getPlayerStats().then();
        this.setPlayedMissions().then();
        this.backend.startUserSession().then()
        this.backend.getPlayerMe().then(r => {
            this.setState({
                nickname: r.nickname,
            });
        });
    }

    //render list of played missions with links
    renderPlayedMissions() {
        return this.state.progress.missionProgress.map((mission, index) => {
            let mode = mission.missionUid.substring(0, 1)
            let difficulty = mission.missionUid.slice(-1)
            let region = mission.missionUid.replace('_', '').substring(1, mission.missionUid.length - 3);
            if (mode === 'D') {
                mode = 'droog'
            }
            if (mode === 'N') {
                mode = 'nat'
            }
            return (
                <div className="played" key={'played_'+index}>
                    <div className="title" key={'missionName_'+mission.missionName}>{mission.missionName}</div>
                    <div className="ranking-container"><div className={"ranking"} key={'missionposition_'+mission.position}>{mission.position}</div></div>
                    <div className="score" key={'missionmissionUid_'+mission.missionUid}>{FormatUtil.formatScore(mission.score)}</div>
                    <div className="stars">
                        <Stars score={mission.score} />
                    </div>
                    <div className="replay-button-container">
                        <Link to={'./../mode/' + mode + '/' + region + '/difficulty/' + difficulty}>
                            <img src={replay} alt="replay button"/>
                        </Link>
                    </div>
                </div>
            );
        })
    }

    //get player sum, avg scores & set to state
    async getPlayerStats() {
        await this.backend.getPlayerMe().then(p => {
            this.backend.getHighscoreModeSum('droog').then(dr => {
                this.backend.getHighscoreModeSum('nat').then(na => {
                    this.backend.getHighscorePositions().then(positions => {
                        let gamesPlayed = Object.keys(positions.data.missionHighscorePositions).length
                        let stars = p.progress.missionProgress.map(value => {
                            if (value.score >= config.mission.stars.threeStars) return Number(3)
                            else if (value.score >= config.mission.stars.twoStars) return 2
                            else if (value.score >= config.mission.stars.oneStar) return 1
                            else return 0
                        }).reduce((a, b) => a + b, 0)

                        let droog = dr.highscores.find(hs => hs.score && hs.playerUid === p.uid);
                        let nat = na.highscores.find(hs => hs.score && hs.playerUid === p.uid);
                        if (!droog) {
                            droog ={score: 0, playerUid: '', playerNickname: ''}
                        }
                        if (!nat) {
                            nat = {score: 0, playerUid: '', playerNickname: ''}
                        }
                        this.setState({
                            playerSum: droog.score + nat.score,
                            playerAvg: (droog.score + nat.score) / gamesPlayed,
                            playerStars: stars
                        });
                    })
                })
            })
        })
    }

    render() {
        return (
            <div className={'score-page'}>
                <div className="illustration-top">
                    <img src={blueIllustration} alt="blue illustration"/>
                </div>
                <PreviousButton previousUrl={'../'}/>
                <div className="
                leaderboard-button">
                    <img src={leaderboardButton} alt="lb button"/>

                    <Link to={'../leaderboard'}>
                        <div className="title">LEADERBOARD</div>
                    </Link>
                </div>
                <div className="score-content">
                    <h1 className="heading">Score</h1>

                    <div className="user-stats">
                        <img src={userStats} alt="user stats"/>
                        <div className="user-info">
                            <div className="title">Naam: <b>{this.state.nickname}</b></div>
                            <div className="title">Totale score: <b>{FormatUtil.formatScore(this.state.playerSum  || 0)}</b></div>
                            <div className="title">Gemiddelde score: <b>{FormatUtil.formatScore(this.state.playerAvg || 0)}</b></div>
                            <div className="title">Totaal aantal sterren: <b>{this.state.playerStars}/117</b></div>
                        </div>
                        <img src={userStatsRev} alt="user stats"/>
                    </div>
                    <div className="mt-5">
                        <div className="played-before header">
                            <h1 className={'heading'}>Missies</h1>
                            <div className="played">
                                <div className="title header">Missie</div>
                                <div className="ranking-container header">Ranking</div>
                                <div className="score header">Score</div>
                                <div className="stars header">Sterren</div>
                                <div className="replay-button-container header"></div>
                            </div>
                            {
                                this.renderPlayedMissions()
                            }
                        </div>
                    </div>
                </div>

                <div className="illustration-bottom">
                    <img src={blueIllustrationBottom} alt=""/>
                </div>
            </div>
        );
    }
}
