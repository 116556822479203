import config from "../config.json";
import {LngLatBounds} from 'mapbox-gl';
import {asEnum} from '../util/Helpers';
import {MissionMode} from '../Mission';

type MissionQuestionConfig = { questionType: string, amount: number, objectKind?: string, isFallback?: boolean };

export interface QuestionConfig {
    questionType: QuestionType,
    objectKind?: string,
    amount: number,
    isFallback?: boolean
}

export enum QuestionType {
    Road = "Road",
    Hectopunt = "Hectopunt",
    Fairway = "Fairway",
    PointOfInterest = "PointOfInterest",
    PathOfInterest = "PathOfInterest",
    FairwayKilometerMark = "FairwayKilometerMark",
    RoadObject = "RoadObject",
    VtsSector = "VtsSector",
}

export class ConfigService {

    private static instance: ConfigService;

    public static getInstance(): ConfigService {
        if (!ConfigService.instance) {
            ConfigService.instance = new ConfigService();
        }

        return ConfigService.instance;
    }

    public getMapboxAccessToken(): string {
        return config.mapbox.accessToken;
    }

    public getMapboxStyleRegionSelect(): string {
        return config.mapbox.styleRegionSelect
    }

    public getDefaultBounds(): LngLatBounds {
        const bbox = config.map.defaultBounds;
        return LngLatBounds.convert([[bbox[0][0], bbox[0][1]], [bbox[1][0], bbox[1][1]]]);
    }

    public getDefaultMaxBounds(): LngLatBounds {
        const bbox = config.map.defaultMaxBounds;
        return LngLatBounds.convert([[bbox[0][0], bbox[0][1]], [bbox[1][0], bbox[1][1]]]);
    }

    public getMissionConfig(mode: MissionMode, missionLevel: number) {
        const modeMissions = config.mission.composition[mode === MissionMode.Droog ? "droog" : "nat"];
        switch (missionLevel) {
            case 1:
                return this.buildQuestionConfigs(modeMissions["1"]);
            case 2:
                return this.buildQuestionConfigs(modeMissions["2"]);
            case 3:
                return this.buildQuestionConfigs(modeMissions["3"]);
            default:
                console.warn(`Unknown mission level ${missionLevel}, falling back to "3"`);
                return this.buildQuestionConfigs(modeMissions["3"]);
        }
    }

    private buildQuestionConfigs(missionComposition: MissionQuestionConfig[]): QuestionConfig[] {
        return missionComposition.map(q => ({
            questionType: asEnum(QuestionType, q.questionType),
            amount: q.amount,
            objectKind: "objectKind" in q ? q.objectKind : undefined,
            isFallback: q.isFallback
        }));
    }

    public determineStars(score?: number): number {
        if (!score) return 0;

        if (score >= config.mission.stars.threeStars) {
            return 3;
        } else if (score >= config.mission.stars.twoStars) {
            return 2
        } else if (score >= config.mission.stars.oneStar) {
            return 1;
        } else {
            return 0;
        }
    }
}
