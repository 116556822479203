import React from "react";
import '../styles/_login.scss';
import {BackendService} from "../data/BackendService";
import {NavigateFunction} from 'react-router-dom';
import withNavigate from '../util/WithNavigate';

export interface LoginPageState {
    username: string,
    password: string
}

export interface LoginPageProps {
    startPath?:string,
    navigate: NavigateFunction
}

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
    private backend = BackendService.getInstance();
    constructor(props: LoginPageProps) {
        super(props);
        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onSubmitForm(event: any) {
        event.preventDefault();
        this.backend.login(this.state.username, this.state.password, this.props.startPath)
            .then(r => {
                console.log(this.props.startPath)
                if (this.props.startPath) {
                    this.props.navigate(this.props.startPath, {replace: true});
                } else {
                    this.props.navigate("/", {replace: true});
                }
            })
            .catch(e => {
                    alert("Het inloggen is niet gelukt. Controleer je gebruikersnaam en wachtwoord");
                    console.error("Login failed", e);
                });
    }

    componentDidMount() {
        this.setState({
            username: '',
            password: ''
        })
    }

    onInputchange(event: any) {
        if (event.currentTarget.name === 'username') {
            this.setState({
                username: event.target.value,

            });
        }
        if (event.currentTarget.name === 'password') {
            this.setState({
                password: event.target.value,
            });
        }

    }

    render() {
        return (
            <div>
                <div className="heading">
                    <h1>Login</h1>
                </div>
                <div className={'form-login'}>
                    <form onSubmit={e => this.onSubmitForm(e)} className="form">
                        <input onChange={e => this.onInputchange(e)} name={'username'} type="text" placeholder={'username'}/>
                        <input onChange={e => this.onInputchange(e)} name={'password'} type="password"
                               placeholder={'password'}/>
                        <button type={'submit'}>Login</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withNavigate(LoginPage)
