import React from "react";
import {PreviousButton} from "../components/PreviousButton";
import '../styles/_nameGenerator.scss';
import {BackendService} from "../data/BackendService";
import blob from "../img/blob-top-right.svg";
import nameGen from '../img/nameGen.svg';
import playButton from "../img/play-button.svg";
export interface NameGeneratorState {
    nickname: string
}

export interface NameGeneratorProps {
}

export class NameGenerator extends React.Component<NameGeneratorProps, NameGeneratorState> {
    private backend = BackendService.getInstance();

    constructor(props: NameGeneratorProps) {
        super(props);
        this.state = {
            nickname: ''
        }
    }

    componentDidMount() {
        this.generateNickname();
    }

    generateNickname() {
        this.backend.generateNickname().then(r => {
            this.setState({
                nickname: r
            })
        })
    }

    setNickname() {
        if (this.state.nickname === '' || null) {
            this.backend.generateNickname().then(r =>{
                this.backend.setPlayerNickname(r).then(()=> window.location.href ='/');
            })
        } else {
            this.backend.setPlayerNickname(this.state.nickname).then(()=> window.location.href ='/');
        }
    }

    render() {
        return (
            <div>
                <div className="illustration">
                    <img src={blob} alt="blob"/>
                </div>
                <PreviousButton previousUrl={'./../'}/>
                <div className="name-generator">
                    <h1 className={'heading mt-20'}>NAME GENERATOR</h1>
                    <h3>Jouw naam is:</h3>
                    <div className="input">
                        <div className="name-input">
                            {this.state.nickname}
                        </div>
                    </div>
                    <div className="generator">
                        <div className="generator-button" onClick={() => this.generateNickname()} >
                            <img src={nameGen} alt=""/>

                            <div className="title">
                                Een nieuwe naam genereren
                            </div>
                        </div>
                    </div>
                </div>
                <div className="next-button" onClick={() => this.setNickname()}>
                    <img src={playButton} alt="play button"/>
                    <div className="title">VOLGENDE</div>
                </div>
            </div>
        );
    }
}
