import {Road} from "../data/GeoData";
import {Component} from "react";
import '../styles/_sign.scss';
export interface RoadSignProps {
  road: Road,
  size: 'large' | 'small'
}

const classesLarge = "w-32 h-16 text-4xl";
const classesSmall = "w-28 h-auto text-xl";

export class RoadSign extends Component<RoadSignProps, {}> {

  wegType(): "A" | "N" {
    return this.props.road.wegnummer.startsWith("A") ? "A" : "N";
  }


  render() {
    return (
      <div className={"border-2 rounded-lg border-white p-1 m-1 " +
        "inline-flex items-center justify-center " +
        (this.props.size === "large" ? classesLarge : classesSmall) + " " +
        (this.wegType() === "A" ? "bg-main-road-red text-white" : "bg-national-road-yellow text-black")}>
        <div>{this.props.road.wegnummer}</div>
      </div>
    );
  }
}
