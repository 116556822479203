import Loading from "./components/Loading";
import {useSearchParams} from "react-router-dom";
import {BackendService} from "./data/BackendService";
import {useEffect} from "react";

function AppInit() {

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const backend = BackendService.getInstance();
    if (!backend.isInitialized.value && !backend.isInitializing.value) {
      console.log("Let's initialize the back-end");
      backend.initialize(searchParams.get("token"), searchParams.get("mission"));

      backend.isInitialized.subscribe(s => {
        if (s) {

          console.log("Backend initialized!");
          searchParams.delete("token");
          searchParams.delete("mission");

          setSearchParams(searchParams, {replace: true});
        }
      });

    }
  });

  return <Loading text={"Locatie Spotter wordt geladen..."} />
}

export default AppInit
