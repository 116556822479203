import {MissionMode} from "./Mission";
import React, {useEffect} from "react";
import {BackendService} from "./data/BackendService";
import {useNavigate} from "react-router-dom";

function RedirectToRestrictedMission() {

  const navigate = useNavigate();

  useEffect(() => {
    const backend = BackendService.getInstance();
    if (!backend.missionRestriction.value) return;

    const mission = determineMissionParams(backend.missionRestriction.value);
    navigate("/mode/" + (mission.mode === MissionMode.Droog ? "droog" : "nat") + "/" + mission.region + "/difficulty/" + mission.level, {replace: true});
  });
  return <>Redirecting...</>
}

function determineMissionParams(missionUid: String): { mode: MissionMode, region: string, level: number } {

  const mode = missionUid[0] === "D" ? MissionMode.Droog : MissionMode.Nat;
  const region = missionUid.substring(2, missionUid.length - 2);
  const level = Number.parseInt(missionUid[missionUid.length - 1]);

  return {mode, region, level};

}

export default RedirectToRestrictedMission;
