import React from "react";
import {useParams} from "react-router-dom";
import {Difficulty} from "./Difficulty";

const DifficultyOptions = () => {
    const params = useParams();
    return (
        <Difficulty mode={params["mode"]!} region={params["region"]!.replaceAll("-", " ")} />
    )
}
export default DifficultyOptions;