
export class MathUtil {

  /**
   * Returns the interpolated value between start and end, given the progress between 0 and 1
   * @param start Start of the interpolation range
   * @param end End of the interpolation range
   * @param progress Number between 0 and 1 representing the total progress
   * @return The interpolated number
   */
  static interpolate(start: number, end: number, progress: number): number {
    return  start + ((end - start) * progress);
  }

  /**
   * Maps the number x from the range between start and end, to the range between mapStart and mapEnd.
   * @param start Start of the range
   * @param end End of the range
   * @param x The number in the range, for which the progress a long the range is desired
   * @param mapStart The start of
   * @param mapEnd
   */
  static map(start: number, end: number, x: number, mapStart: number = 0, mapEnd: number = 1) {
    return (((x - start) / (end - start)) * (mapEnd - mapStart)) + mapStart;
  }

  static clamp(x: number, start: number, end: number) {
    if (x < start) return start;
    else if (x > end) return end;
    return x;
  }

  static sum(values: number[]): number {
    return values.reduce((a, b) => a + b, 0);
  }
}
