import {FairwayKilometerMark} from '../data/GeoData';
import {Component} from "react";

export interface FairwayKilometerMarkSignProps {
  kilometerMark: FairwayKilometerMark,
  size: 'large' | 'small'
}

export class FairwayKilometerMarkSign extends Component<FairwayKilometerMarkSignProps, {}> {

  render() {
    return (
      <div className={'km-mark'}>
        <div className="title">
          {this.props.kilometerMark.fairwayName }
        </div>
        <div className="km-title">
          {this.props.kilometerMark.number}
        </div>
      </div>
    );
  }
}
