import React, {useState} from 'react';
import './App.scss';
import {useSearchParams} from 'react-router-dom'
import {BackendService} from './data/BackendService';
import AppRouter from './AppRouter';
import AppInit from './AppInit';

type AppState = {
  loading: boolean,
}

function App() {

  useState<AppState>({
    loading: true,
  });

  // HACK: for some reason, the game doesn't get initialized unless this is called
  useSearchParams()

  return (
    <div className="game-container">
      {
        !BackendService.getInstance().isInitialized.value
          ? (<AppInit />)
          : <AppRouter />
      }
    </div>
  )

}

export default App;
