import React from 'react';
import {ConfigService} from "../config/ConfigService";
import grayStar from "../img/gray-star.svg";
import goldStar from "../img/gold-star.svg";

interface StarsProps {
  score?: number;
}

const Stars = (props: StarsProps) => {
  let star = ConfigService.getInstance().determineStars(props.score);
  switch (star) {
    case 1:
      return (
        <div className={'stars-inner'}>
          <img src={goldStar} alt="gold star"/><img src={grayStar} alt="gray star"/><img src={grayStar} alt="gray star"/>
        </div>
      )
    case 2:
      return (
        <div className={'stars-inner'}>
          <img src={goldStar} alt="gold star"/><img src={goldStar} alt="gold star"/><img src={grayStar} alt="gray star"/>
        </div>
      )
    case 3:
      return (
        <div className={'stars-inner'}>
          <img src={goldStar} alt="gold star"/><img src={goldStar} alt="gold star"/><img src={goldStar} alt="gold star"/>
        </div>
      )
    default:
      return (
        <div className={'stars-inner'}>
          <img src={grayStar} alt="gray star"/><img src={grayStar} alt="gray star"/><img src={grayStar} alt="gray star"/>
        </div>
      );
  }
}

export default Stars;
