import React from "react";
import {Link} from "react-router-dom";
import {BackendService} from "../data/BackendService";
import '../styles/_missionQuitButton.scss';
import closePopup from '../img/closePopUp.svg';
export interface MissionQuitButtonProps {

}
export interface MissionQuitButtonState {
    showPopup: boolean
}

export class MissionQuitButton extends React.Component<MissionQuitButtonProps, MissionQuitButtonState>{
    private backend = BackendService.getInstance();
    constructor(props: MissionQuitButtonProps) {
        super(props);
        this.state = {
            showPopup: false
        }
    }
    closePopup(){
        this.setState({
            showPopup: false
        })
    }
    openPopup(){
        this.setState({
            showPopup: true
        })
    }
    render() {
        return (
            <>
            <div onClick={() => this.openPopup()} className={'hamburger-closed mission-closed-button'}>
                    <div className="burger-slices">
                        <span className="line line-1"></span>
                        <span className="line line-2"></span>
                        <span className="line line-3"></span>
                    </div>
            </div>


                <div className={this.state.showPopup ? 'close-game-popup' : 'close-game-closed'}>
                    <div className="popup-inner">
                        <div onClick={() => this.closePopup()} className="exit">
                            <img src={closePopup} alt=""/>
                        </div>
                        <p>Weet je zeker dat je de missie wilt afsluiten?</p>
                        <div className="buttons">
                            <Link to={'./../../../../'} onClick={() => this.backend.missionAbort()} > <button>Ja</button></Link>
                            <button onClick={() => this.closePopup()}>Nee</button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}