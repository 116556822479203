import React, {ReactNode} from "react";
import {RoadObject} from "../data/GeoData";
import './../styles/_roadObjectSign.scss';
import junctions from '../img/junction.svg';
import brug from '../img/brug.svg'
import tunnel from '../img/tunnel.svg'
interface RoadObjectSignProps {
    roadObject: RoadObject,
    size: 'large' | 'small'
}

export class RoadObjectSign extends React.Component<RoadObjectSignProps, any> {

    wegType(): "Brug" | "Tunnel" | "Knooppunt" {
        if (this.props.roadObject.objectKind.includes('Bruggen')) {
            return "Brug";
        } else if (["Open Tunnelbakken", "Tunnels"].includes(this.props.roadObject.objectKind)) {
            return "Tunnel";
        } else {
            return "Knooppunt";
        }
    }

    objectKindClassName(): string {
        switch (this.wegType()) {
            case "Brug": return "brug";
            case "Tunnel": return "tunnel";
            case "Knooppunt": return "knooppunt";
        }
    }

    renderIcon(): ReactNode {
        switch (this.wegType()) {
            case "Brug":
                return <div className="image">
                    <img src={brug} alt=""/>
                </div>
            case "Tunnel":
                return <div className="image">
                    <img src={tunnel} alt=""/>
                </div>
            case "Knooppunt":
                return <div className="image"><img src={junctions} alt="Knooppunt" width="80px"/></div>
        }
    }

    render() {
        return (
            <div className={this.objectKindClassName()}>
                {this.renderIcon()}
                <div className={'titles'}>
                    {this.props.roadObject.structureName === this.props.roadObject.objectName ?
                        <div className={'title'}>{this.props.roadObject.structureName}<br/></div>
                        :
                        <div>
                            <div className={'title'}>{this.props.roadObject.structureName}<br/></div>
                            <div className={'title title-small'}>{this.props.roadObject.objectName}</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
